import React from 'react';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import classNames from 'classnames';
import styles from '../EditProjectUser.module.scss';
import { SecondaryButton } from 'src/shared/components/button/Button';
import StatusIndicator from 'src/shared/components/status-indicator/StatusIndicator';
import { setEditUserFormMode, setEditUserFormState, setEditUserView } from '../../../state/userManagementSlice';
import { Access } from 'src/shared/types/graph/user';
import { getUserStatusFromEnum } from '../../user-management-list/UserManagementList';
import { useAppDispatch, useAppSelector } from 'src/store';
import { getEditProjectUserFormState } from '../../../state/userManagementSelector';

import { useResendInvitationEmailMutation } from '../../../state/api/userManagementGraphSlice';
import { batch } from 'react-redux';
import useCustomer from 'src/app/customers/hooks/useCustomer';
import { Status } from 'src/shared/types/status';
import StatusChip from 'src/shared/components/status-chip/StatusChip';

type EditProjectUserHeaderProps = {
    setErrorMessage: (message: string) => void;
    hideEditLink?: boolean;
};

const EditProjectUserHeader = ({ setErrorMessage, hideEditLink }: EditProjectUserHeaderProps) => {
    const dispatch = useAppDispatch();
    const formState = useAppSelector(getEditProjectUserFormState);
    const { firstName, lastName, email, status, phone, canResendEmailInvitation } = formState;
    const { currentCustomerId } = useCustomer();
    const userNameClass = classNames(styles.name, styles['name' + status]);
    const [resendInvitationEmail] = useResendInvitationEmailMutation();
    const isAssignedToCustomer = formState.customers.some(customer => customer.customerId === currentCustomerId && customer.active);
    const isActiveOrInvited = status === 'Active' || status === 'Invited';

    const setResendFlag = (flag: boolean) => {
        dispatch(
            setEditUserFormState({
                canResendEmailInvitation: flag,
            })
        );
    };
    const handleResendEmail = async () => {
        try {
            await resendInvitationEmail({
                email: email,
            }).unwrap;
            setResendFlag(false);
        } catch (error) {
            console.error('rejected', error);
            setErrorMessage('Unable to resend the invitation email. Please try again later.');
        }
    };

    return (
        <div className={styles.editProjectUserHeader}>
            <div className={styles.userDetails}>
                <div className={styles.avatar}>{`${firstName[0]}${lastName[0]}`}</div>
                <div className={styles.info}>
                    <div className={userNameClass}>{`${firstName} ${lastName}`}</div>
                    <div>{email}</div>
                    {phone && <div>{phone}</div>}
                    <StatusChip label={status} status={getUserStatusFromEnum(status)} />
                </div>
            </div>

            <div className={styles.sectionRow}>
                <StatusIndicator
                    status={isAssignedToCustomer ? Status.SUCCESS : isActiveOrInvited ? Status.WARNING : Status.ERROR}
                    statusText={
                        isAssignedToCustomer
                            ? 'Assigned to this org'
                            : isActiveOrInvited
                            ? 'Invited to this org'
                            : 'Unassigned from this org'
                    }
                />
                {isActiveOrInvited && !hideEditLink && (
                    <div
                        className={styles.editText}
                        onClick={() => {
                            batch(() => {
                                dispatch(setEditUserFormMode(Access.UPDATE));
                                dispatch(setEditUserView('edit-user-details'));
                            });
                        }}
                    >
                        Edit user details
                    </div>
                )}
            </div>

            {status === 'Invited' && (
                <div className={styles.resendEmailContainer}>
                    <span className={styles.row}>
                        <span className={styles.info}>
                            <InfoOutlinedIcon />
                            This user has not accepted their email invitation.
                        </span>
                    </span>
                    <span className={styles.row}>
                        <SecondaryButton onClick={handleResendEmail} disabled={!canResendEmailInvitation}>
                            <EmailOutlinedIcon /> &nbsp; {!canResendEmailInvitation ? 'Email invitation resent' : 'Resend email invitation'}
                        </SecondaryButton>
                    </span>
                </div>
            )}
        </div>
    );
};

export default EditProjectUserHeader;
