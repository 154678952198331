import { gql } from 'graphql-request';
import { User } from 'src/shared/types/graph/user';

export const getCustomerUsersForSuperAdminQuery = gql`
    query getCustomerUsersForSuperAdmin($customerId: String!, $showOnlyExternalUsers: Boolean!) {
        admin {
            users(customer: $customerId, showOnlyExternalUsers: $showOnlyExternalUsers) {
                id
                cognitoId
                firstName
                lastName
                email
                phone
                phoneCountryCode
                customers {
                    customerId
                    roles
                    active
                    updatedAt
                }
                projects {
                    projectId
                    roles
                }
                tnc_consent
                is_internal_user
                status
            }
        }
    }
`;

export type GetCustomerUsersForSuperAdminQueryResult = {
    admin: {
        users: User[];
    };
};

export const createUserQuery = gql`
    mutation admin($input: CreateUserInput!) {
        admin {
            createUser(input: $input) {
                id
            }
        }
    }
`;

export const updateUserQuery = gql`
    mutation admin($input: UpdateUserInput!, $cognitoId: String!) {
        admin {
            updateUser(input: $input, cognitoId: $cognitoId)
        }
    }
`;

export type CreateUserQueryResult = {
    id: string;
};

export const getCustomerAdminUsersQuery = gql`
    query getCustomerAdminUsers($customerId: String!) {
        customerAdmins(customerId: $customerId) {
            id
            cognitoId
        }
    }
`;

export type GetCustomerAdminUsersQueryResult = {
    customerAdmins: {
        id: string;
        cognitoId: string;
    }[];
};

export const hasCustomerAdminUserQuery = gql`
    query hasCustomerAdminUser($customerId: String!) {
        hasCustomerAdmin(customerId: $customerId)
    }
`;

export type HasCustomerAdminUserQueryResult = {
    hasCustomerAdmin: boolean;
};

export const createProjectUserQuery = gql`
    mutation createProjectUser(
        $firstName: String!
        $lastName: String!
        $email: String!
        $phone: String!
        $projects: [UserProjectAccessInput]!
        $customerId: String!
    ) {
        createProjectUser(
            firstName: $firstName
            lastName: $lastName
            email: $email
            phone: $phone
            projects: $projects
            customerId: $customerId
        ) {
            id
        }
    }
`;

export type CreateProjectUserQueryResult = {
    id: string;
};

export const getCustomerUsersQuery = gql`
    query getCustomerUsers($customerId: String!) {
        users(customerId: $customerId) {
            id
            cognitoId
            firstName
            lastName
            email
            phone
            phoneCountryCode
            customers {
                customerId
                roles
                active
                updatedAt
            }
            projects {
                projectId
                roles
            }
            tnc_consent
            status
        }
    }
`;

export type GetCustomerUsersQueryResult = {
    users: User[];
};

export const checkUsernameAvailableQuery = gql`
    query checkUsernameAvailable($email: String!) {
        usernameAvailable(email: $email)
    }
`;

export type CheckUsernameAvailableQueryResult = {
    usernameAvailable: boolean;
};

export type GetUserListSummaryResult = {
    userCountSummary: {
        projectId: string;
        creationRemark: string;
        countByRoles: {
            name: string;
            roleCount: number;
            limitation: {
                max: number;
                softLimit: number;
            };
        }[];
    }[];
};

export const updateProjectUserQuery = gql`
    mutation updateMyOrganisationUser(
        $firstName: String!
        $lastName: String!
        $phone: String!
        $projects: [UserProjectAccessInput]!
        $customerId: String!
        $tnc_consent: Boolean!
        $cognitoId: String!
        $notify: Boolean!
    ) {
        updateMyOrganisationUser(
            firstName: $firstName
            lastName: $lastName
            phone: $phone
            projects: $projects
            customerId: $customerId
            tnc_consent: $tnc_consent
            cognitoId: $cognitoId
            notify: $notify
        )
    }
`;

export const enableProjectuserQuery = gql`
    mutation enableProjectuser($email: String!, $notify: Boolean!) {
        admin {
            enableUser(email: $email, notify: $notify)
        }
    }
`;

export const disableProjectuserQuery = gql`
    mutation disableProjectuser($email: String!, $notify: Boolean!) {
        admin {
            disableUser(email: $email, notify: $notify)
        }
    }
`;

export const resendInvitationEmailQuery = gql`
    mutation resendInvitation($email: String!) {
        resendInvitationEmail(email: $email)
    }
`;

export const getUserListSummaryQuery = gql`
    query getUserListSummary($projectIds: [String]) {
        userCountSummary(projectIds: $projectIds) {
            projectId
            creationRemark
            countByRoles {
                name
                roleCount
                limitation {
                    max
                    softLimit
                }
            }
        }
    }
`;

export type GetUserResult = {
    admin: {
        user: User;
    };
};

export const getUserQuery = gql`
    query admin($email: String!) {
        admin {
            user(email: $email) {
                id
                cognitoId
                firstName
                lastName
                email
                phone
                phoneCountryCode
                customers {
                    customerId
                    roles
                }
                projects {
                    projectId
                    roles
                }
                tnc_consent
                status
                is_internal_user
            }
        }
    }
`;

export const unassignUserFromOrganisationQuery = gql`
    mutation unassignUserFromOrganisation($id: Int!, $customerId: String!, $notify: Boolean!) {
        unassignUserFromOrganisation(id: $id, customerId: $customerId, notify: $notify)
    }
`;

export const reassignUserFromOrganisationQuery = gql`
    mutation reassignUserFromOrganisation($id: Int!, $customerId: String!, $notify: Boolean!) {
        reassignUserFromOrganisation(id: $id, customerId: $customerId, notify: $notify)
    }
`;
