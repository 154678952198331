import { divIcon, PointExpression } from 'leaflet';

export type GetIsMarkerOpaqueParams = {
    activeSampleId: string | null;
    focusedSpeciesScientificName: string | null;
    currentSampleId: string;
    sampleIdsInFocus: string[];
};

export const getIsMarkerOpaque = (params: GetIsMarkerOpaqueParams) => {
    const { activeSampleId, focusedSpeciesScientificName, currentSampleId, sampleIdsInFocus } = params;

    if (!activeSampleId && !focusedSpeciesScientificName) {
        return false;
    }

    if (activeSampleId && activeSampleId === currentSampleId) {
        return false;
    }

    if (focusedSpeciesScientificName && sampleIdsInFocus.includes(currentSampleId)) {
        return false;
    }

    return true;
};

export const getCircleAnchorPosition = (groupOrder: number): PointExpression => {
    const defaultPosition = [0, 0] as PointExpression;

    if (groupOrder === 0) {
        return defaultPosition;
    }
    const x = [0, -4, 5].at(groupOrder % 3);

    const y = 4 * Math.ceil(groupOrder / 3) + (groupOrder % 3 === 0 ? 4 : 0) + (groupOrder > 3 ? 4 : 0);

    return [x, y] as PointExpression;
};

export const getCircleIcon = (color: string, markerRadius: number, groupOrder: number) => {
    const doubleMarkerRadius = markerRadius * 2;
    const iconAnchorPosition = getCircleAnchorPosition(groupOrder) as [number, number];
    const popupTooltipAnchorPosition = [0 - iconAnchorPosition[0], -markerRadius + 2 + iconAnchorPosition[0]] as PointExpression;

    return divIcon({
        iconAnchor: [markerRadius + iconAnchorPosition[0], markerRadius + iconAnchorPosition[1]],
        iconSize: [doubleMarkerRadius, doubleMarkerRadius],
        popupAnchor: popupTooltipAnchorPosition,
        tooltipAnchor: popupTooltipAnchorPosition,
        html: `
        <svg width="${doubleMarkerRadius}" height="${doubleMarkerRadius}" viewBox="0 0 ${doubleMarkerRadius} ${doubleMarkerRadius}" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="${markerRadius}" cy="${markerRadius}" r="${
            markerRadius - 1
        }" stroke="${color}" fill-opacity=".5" stroke-width="2" fill="${color}"/>
        </svg>
            `,
        className: '',
    });
};

export const getAnchorPosition = (groupOrder: number): PointExpression => {
    const defaultPosition = [12, 35] as PointExpression;

    if (groupOrder === 0) {
        return defaultPosition;
    }
    const x = [12, 8, 17].at(groupOrder % 3);

    const y = 35 + 4 * Math.ceil(groupOrder / 3) + (groupOrder % 3 === 0 ? 4 : 0) + (groupOrder > 3 ? 4 : 0);

    return [x, y] as PointExpression;
};

export const getPopupAnchorPosition = (groupOrder: number): PointExpression => {
    const defaultPosition = [1, -30] as PointExpression;

    if (groupOrder === 0) {
        return defaultPosition;
    }
    const x = [1, 5, -5].at(groupOrder % 3);

    const y = -30 - 4 * Math.ceil(groupOrder / 3) - (groupOrder % 3 === 0 ? 4 : 0) - (groupOrder > 3 ? 4 : 0);

    return [x, y] as PointExpression;
};

export const getPinDivIcon = (color: string, groupOrder: number) => {
    const iconAnchorPosition = getAnchorPosition(groupOrder);
    const popupAnchorPosition = getPopupAnchorPosition(groupOrder);

    return divIcon({
        iconAnchor: iconAnchorPosition,
        popupAnchor: popupAnchorPosition,
        tooltipAnchor: popupAnchorPosition,
        html: `
        <svg width="25" height="35" viewBox="0 0 20 35" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="m3.54137 4.22824c-1.58565 1.65255-2.578851 4.05986-2.54029 6.32416.08054 4.7318 2.26467 6.523 5.75749 13.0354 1.25819 2.8657 2.57135 5.8979 3.82033 10.9201.1735.7407.3428 1.4289.4211 1.4885.0783.0597.2476-.6309.4212-1.3717 1.2489-5.0221 2.562-8.052 3.8202-10.9177 3.4929-6.5124 5.677-8.3036 5.7575-13.0354.0386-2.26429-.9571-4.67401-2.5427-6.32656-1.8114-1.88776-4.5435-3.28485-7.4562-3.34327-2.91273-.05846-5.64728 1.3387-7.45863 3.22647z" fill="${color}" stroke="#fff"/>
            <circle cx="10.5" cy="10" r="3" fill="#fff"/>
        </svg>
            `,
        className: '',
    });
};
