import React from 'react';
import isEmpty from 'lodash/isEmpty';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import IUCNLogo from 'src/assets/images/dashboard/iucn.png';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { TaxonomySummary } from 'src/shared/types/graph/project';
import { iucnStatusData } from 'src/shared/helpers/iucnStatusHelper';
import QuestionButtonHelp from 'src/shared/components/question-button-help/QuestionButtonHelp';
import styles from './IucnTable.module.scss';
import styled from '@mui/material/styles/styled';
import Typography from '@mui/material/Typography';

interface IucnTableProps {
    data: TaxonomySummary;
    title: string;
    helpArticleSlug: string;
    type: string;
}
const IucnTable = ({ data, title, helpArticleSlug, type }: IucnTableProps) => {
    const evenRow = '#F5F5F5';
    const oddRow = '#E6E6E6';
    const StyledTableCell = styled(TableCell)(() => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: evenRow,
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 12,
            color: '#262D38',
        },
    }));

    const StyledTableRow = styled(TableRow)(() => ({
        '&:nth-of-type(odd)': {
            backgroundColor: oddRow,
        },

        '&:nth-of-type(even)': {
            backgroundColor: evenRow,
        },
    }));

    return (
        <div className={styles.iucnTable}>
            <div className={styles.logoContainer}>
                {type === 'redLists' ? (
                    <img src={IUCNLogo} alt='' className={styles.logo} />
                ) : (
                    <InfoOutlinedIcon className={`${styles.logo} ${styles.inverted}`} />
                )}
                <Typography variant='h2' component='span' className={styles.title}>
                    {title}
                    <span className={styles.questionButton}>
                        <QuestionButtonHelp
                            type='api'
                            slug={helpArticleSlug}
                            detailedExplainerSlug={`${helpArticleSlug}-te`}
                            showHelpTitle={false}
                        />
                    </span>
                </Typography>
            </div>
            <TableContainer>
                <Table aria-label='customized table' className={styles.table}>
                    <TableHead className={styles.tableHeader}>
                        <TableRow>
                            <StyledTableCell>Scientific Name</StyledTableCell>
                            {type === 'redLists' && <StyledTableCell align='left'>IUCN Red List status</StyledTableCell>}
                            <StyledTableCell align='left'>Number of Samples</StyledTableCell>
                            <StyledTableCell align='left'>% of samples</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {!isEmpty(data) ? (
                            data.map((row: any) => (
                                <StyledTableRow key={row.taxonomy.tax_species}>
                                    <StyledTableCell component='th' scope='row'>
                                        {row.taxonomy.tax_species}
                                    </StyledTableCell>
                                    {type === 'redLists' && (
                                        <StyledTableCell align='left'>
                                            {iucnStatusData[row.conservationStatus.iucnRedListStatus].label}
                                        </StyledTableCell>
                                    )}
                                    <StyledTableCell align='left'>{row.sampleCount}</StyledTableCell>
                                    <StyledTableCell align='left'>{Math.round(row.percentTotalSamples)}</StyledTableCell>
                                </StyledTableRow>
                            ))
                        ) : (
                            <tr>
                                <td>There were no {title} detected in this dataset</td>
                            </tr>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
};

export default IucnTable;
