import { getCurrentCustomerDetails, getCurrentCustomerId, getAllCustomers } from 'src/app/customers/state/customersSelector';
import { useAppDispatch, useAppSelector } from 'src/store';
import { setCurrentCustomerId as setCurrentCustomerIdAction } from '../state/customersSlice';

const useCustomer = () => {
    const currentCustomerId = useAppSelector(getCurrentCustomerId) || '';
    const currentCustomerDetails = useAppSelector(getCurrentCustomerDetails);
    const allCustomers = useAppSelector(getAllCustomers);

    const dispatch = useAppDispatch();

    const setCurrentCustomerId = (customerId: string) => {
        dispatch(setCurrentCustomerIdAction(customerId));
    };

    return {
        allCustomers,
        currentCustomerId,
        currentCustomerName: currentCustomerDetails?.customerName,
        setCurrentCustomerId,
    };
};

export default useCustomer;
