import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import ChevronRight from '@mui/icons-material/KeyboardArrowRight';
import ChevronLeft from '@mui/icons-material/KeyboardArrowLeft';
import DoneIcon from '@mui/icons-material/Done';
import { PrimaryButton, SecondaryButton } from 'src/shared/components/button/Button';
import { useAppDispatch, useAppSelector } from 'src/store';
import { getCurrentProjectDefinitionFormStep } from '../../state/newProjectDefinitionSelector';
import { reset, setCurrentStep } from 'src/app/projects/views/new-project-definition/state/newProjectDefinitionSlice';
import { getCurrentCustomerDetails } from 'src/app/customers/state/customersSelector';
import { availableAreaColors } from 'src/shared/constants/colors';
import QuestionButtonHelp from 'src/shared/components/question-button-help/QuestionButtonHelp';
import styles from './ReviewAndSubmit.module.scss';
import useProjectDefinition from 'src/app/projects/views/new-project-definition/hooks/useProjectDefinition';
import Modal from 'src/shared/components/modal/Modal';
import { STANDARD_DATE_FORMAT } from 'src/shared/constants/dateConstants';
import { useLazyProjectsQuery } from 'src/app/projects/views/project-list/state/api/projectListGraphSlice';
import ConditionalField from 'src/shared/components/conditional-field/ConditionalField';
import { ProjectSamplingCadenceType, SubscriptionType } from 'src/shared/types/graph/project';
// TODO: ProjectDefinitionMap import ReviewSamples from './components/ReviewSamples';

const ReviewAndSubmit = () => {
    const { formState, isBasicAndGenericProject } = useProjectDefinition();
    const formStep = useAppSelector(getCurrentProjectDefinitionFormStep);

    const navigate = useNavigate();
    const { submitProjectDefinitionForm } = useProjectDefinition();
    const currentCustomerDetails = useAppSelector(getCurrentCustomerDetails) || { customerName: '', customerId: '' };
    const [refetchProjects] = useLazyProjectsQuery();

    const [shouldShowModal, showModal] = useState(false);

    const dispatch = useAppDispatch();

    const onStepChange = (step: number) => {
        dispatch(setCurrentStep(step));
    };

    const onSubmit = async () => {
        // TODO: Add error handling here
        try {
            await submitProjectDefinitionForm();
            showModal(true);
        } catch (e) {
            console.error(e);
        }
    };

    const backToProjectAdmin = () => {
        dispatch(reset());
        refetchProjects({
            customerId: currentCustomerDetails.customerId,
        });
        navigate('/projects/project-admin', { state: { sourcePage: 'new-project-definition' } });
    };

    const {
        projectName,
        projectCode,
        subscriptionType,
        country,
        area,
        projectLeadRole,
        projectType,
        targetOutcomes,
        samplingCadence,
        samplingEvents,
        samplingEventsPerYear,
        sampleGroups,
        initialSampleEstimate,
        speciesOfInterest,
        habitatAssayTypes,
        estimatedSampleCountForTestTypes,
        projectLeadEmail,
        projectLeadName,
        projectEndDate,
    } = formState;

    const isInsightsSubscription = subscriptionType === SubscriptionType.INSIGHTS;

    return (
        <>
            <div className={styles.reviewAndSubmit}>
                <div className={styles.section}>
                    <div className={styles.sectionTitle}>
                        <span>Project details</span>
                        <span className={styles.edit} onClick={() => onStepChange(0)}>
                            <EditOutlinedIcon /> Edit
                        </span>
                    </div>
                    <div className={styles.sectionItems}>
                        <div className={styles.sectionItem}>
                            <div className={styles.label}>Customer name</div>
                            <div className={styles.value}>{currentCustomerDetails.customerName}</div>
                        </div>

                        <div className={styles.sectionItem}>
                            <div className={styles.label}>Project name</div>
                            <div className={styles.value}>{projectName}</div>
                        </div>

                        <div className={styles.sectionItem}>
                            <div className={styles.label}>Project code</div>
                            <div className={styles.value}>{projectCode}</div>
                        </div>

                        <div className={styles.sectionItem}>
                            <div className={styles.label}>Subscription type</div>
                            <div className={styles.value}>{subscriptionType}</div>
                        </div>

                        <div className={styles.sectionItem}>
                            <div className={styles.label}>Sampling country</div>
                            <div className={styles.value}>{country}</div>
                        </div>

                        <div className={styles.sectionItem}>
                            <div className={styles.label}>
                                Project end date <QuestionButtonHelp type='api' slug='project-definition-end-date' />
                            </div>
                            <ConditionalField showWarning={false} isSatisfied={subscriptionType !== SubscriptionType.BASIC}>
                                <div className={styles.value}>{moment(projectEndDate).format(STANDARD_DATE_FORMAT)}</div>
                            </ConditionalField>
                        </div>
                    </div>
                    <div className={styles.sectionItems}>
                        <div className={styles.sectionItem}>
                            <div className={styles.label}>Area</div>
                            <ConditionalField showWarning={false} isSatisfied={subscriptionType !== SubscriptionType.BASIC}>
                                <div className={styles.value}>{area}</div>
                            </ConditionalField>
                        </div>
                        <div className={styles.sectionItem}>
                            <div className={styles.label}>Customer contact</div>
                            <div className={styles.customerContact}>
                                <div className={styles.userIcon}>
                                    <PersonOutlineOutlinedIcon />
                                </div>
                                <div className={styles.contactDetails}>
                                    <div className={styles.contactDetailsName}>{projectLeadName}</div>
                                    <div>{projectLeadEmail}</div>
                                </div>
                                <div className={styles.role}>
                                    <div>{projectLeadRole}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={styles.section}>
                    <div className={styles.sectionTitle}>
                        <span>Project aims</span>
                        <span className={styles.edit} onClick={() => onStepChange(1)}>
                            <EditOutlinedIcon /> Edit
                        </span>
                    </div>
                    <div className={styles.sectionItems}>
                        <div className={styles.sectionItem}>
                            <div className={styles.label}>
                                Project type <QuestionButtonHelp type='api' slug='project-definition-project-type' />
                            </div>
                            <div className={styles.value}>{projectType}</div>
                        </div>

                        <div className={styles.sectionItem}>
                            <div className={styles.label}>Target outcomes</div>
                            <ConditionalField showWarning={false} isSatisfied={!isBasicAndGenericProject}>
                                <div className={styles.value}>{targetOutcomes}</div>
                            </ConditionalField>
                        </div>
                    </div>
                    <div className={styles.sectionItems}>
                        <div className={styles.sectionItem}>
                            <div className={styles.label}>
                                Sample groups <QuestionButtonHelp type='api' slug='project-definition-sample-groups' />
                            </div>
                            <ConditionalField showWarning={false} isSatisfied={!isBasicAndGenericProject}>
                                <div className={styles.sampleGroups}>
                                    {sampleGroups.map((entry, index) => {
                                        return (
                                            <div key={index} className={styles.sampleGroupTag}>
                                                <div
                                                    className={styles.sampleGroupTagColor}
                                                    style={{ backgroundColor: availableAreaColors[index] }}
                                                ></div>
                                                <div className={styles.sampleGroupTagValue}>{entry}</div>
                                            </div>
                                        );
                                    })}
                                </div>
                            </ConditionalField>
                        </div>

                        <div className={styles.sectionItem}>
                            <div className={styles.label}>Species of interest</div>
                            <ConditionalField showWarning={false} isSatisfied={!isBasicAndGenericProject}>
                                <div className={styles.sampleGroups}>
                                    {speciesOfInterest.map((entry, index) => {
                                        return (
                                            <div key={index} className={styles.sampleGroupTag}>
                                                <div className={styles.sampleGroupTagValue}>{entry}</div>
                                            </div>
                                        );
                                    })}
                                </div>
                            </ConditionalField>
                        </div>

                        <div className={styles.sectionItem}>
                            <div className={styles.label}>
                                Test <QuestionButtonHelp type='api' slug='project-definition-habitat-test-type' />
                            </div>
                            <div className={styles.habitatAssayTypes}>
                                {habitatAssayTypes.map((entry, index) => {
                                    return (
                                        <div key={index} className={styles.habitatAssayTypeTag}>
                                            <div key={index} className={styles.habitatAssayTypeTagValue}>
                                                <div className={styles.habitatName}>{entry.habitatName}</div>
                                                <div className={styles.assayName}>{entry.assayName}</div>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                </div>

                <div className={styles.section}>
                    <div className={styles.sectionTitle}>
                        <span>Sampling cadence</span>
                        <span className={styles.edit} onClick={() => onStepChange(2)}>
                            <EditOutlinedIcon /> Edit
                        </span>
                    </div>
                    <div className={styles.sectionItems}>
                        <div className={styles.sectionItem}>
                            <div className={styles.label}>Multi year or single year sampling</div>
                            <div className={styles.value}>
                                {samplingCadence === ProjectSamplingCadenceType.SINGLE_YEAR ? 'Single year' : 'Multi year'}
                            </div>
                        </div>

                        <div className={styles.sectionItem}>
                            <div className={styles.label}>
                                Sampling events per year <QuestionButtonHelp type='api' slug='sampling-events-per-year' />
                            </div>
                            <div className={styles.value}>{samplingEventsPerYear}</div>
                        </div>
                    </div>
                    <div className={styles.sectionItems}>
                        <div className={styles.samplingEvents}>
                            {samplingEvents.map((entry, index) => {
                                return (
                                    <div key={index} className={styles.samplingEvent}>
                                        <div className={styles.eventNumber}>
                                            Sampling event {index + 1}{' '}
                                            <QuestionButtonHelp type='api' slug='project-definition-sampling-event' />
                                        </div>
                                        <div className={styles.eventName}>{entry.name}</div>
                                        <div className={styles.datesTitle}>Expected dates</div>
                                        <div className={styles.dates}>
                                            <span className={styles.dateLabel}>From</span>
                                            <span className={styles.dateValue}>{moment(entry.fromDate).format(STANDARD_DATE_FORMAT)}</span>
                                            <span className={styles.dateLabel}>To</span>
                                            <span className={styles.dateValue}>{moment(entry.toDate).format(STANDARD_DATE_FORMAT)}</span>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>

                {!isInsightsSubscription && (
                    <div className={styles.section}>
                        <div className={styles.sectionTitle}>
                            <span>Samples</span>
                            <span className={styles.edit} onClick={() => onStepChange(3)}>
                                <EditOutlinedIcon /> Edit
                            </span>
                        </div>
                        <div className={styles.sectionItems}>
                            <div className={styles.sectionItem}>
                                <div className={styles.label}>Total number of samples</div>
                                <div className={styles.value}>
                                    <span className={styles.totalSamples}>{initialSampleEstimate}</span>
                                </div>
                            </div>
                        </div>
                        <div className={styles.sectionItems}>
                            <div className={styles.sectionItem}>
                                <div className={styles.label}>Number of samples per test type</div>
                                <div className={styles.samplesPerTestType}>
                                    {estimatedSampleCountForTestTypes.map((entry, index) => {
                                        return (
                                            <div key={index} className={styles.samplesPerTestTypeItem}>
                                                <div className={styles.count}>{entry.count}</div>
                                                <div className={styles.habitatName}>{entry.type.habitatName}</div>
                                                <div className={styles.assayName}>{entry.type.assayName}</div>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                )}

                {/* TODO: ProjectDefinitionMap {isInsightsSubscription && (
                    <div className={styles.section}>
                        <div className={styles.sectionTitle}>
                            <span>Samples</span>
                            <span className={styles.edit} onClick={() => onStepChange(3)}>
                                <EditOutlinedIcon /> Edit
                            </span>
                        </div>
                        <ReviewSamples sampleGroups={sampleGroups} initialSampleEntries={initialSampleEntries} />
                    </div>
                )} */}

                <div className={styles.bottomNavigation}>
                    <SecondaryButton onClick={() => onStepChange(2)}>
                        <ChevronLeft /> Back
                    </SecondaryButton>

                    <PrimaryButton onClick={onSubmit}>
                        {formStep === 4 ? (
                            'Submit'
                        ) : (
                            <>
                                Next <ChevronRight />
                            </>
                        )}
                    </PrimaryButton>
                </div>
            </div>
            {shouldShowModal && (
                <Modal>
                    <div className={styles.successModal}>
                        <div className={styles.successTitle}>Success!</div>
                        <div className={styles.successContent}>
                            <span className={styles.successTickIcon}>
                                <DoneIcon />
                            </span>
                            <span className={styles.successMessage}>You have successfully saved a new project definition</span>
                        </div>
                        <div>
                            <PrimaryButton onClick={backToProjectAdmin} className={styles.backButton}>
                                Back to project admin
                            </PrimaryButton>
                        </div>
                    </div>
                </Modal>
            )}
        </>
    );
};

export default ReviewAndSubmit;
