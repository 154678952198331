import React from 'react';
import ContentPasteSearchIcon from '@mui/icons-material/ContentPasteSearch';

import { useAppSelector } from 'src/store';
import { getIsSiteMenuOpen } from 'src/global/state/site-menu/siteMenuSelector';
import classNames from 'classnames';
import { getAppRoutesAvailability, getSurveyRoutesAvailability } from 'src/routes/state/routesSelector';
import { useLocation, useNavigate } from 'react-router-dom';
import { surveyRouteSettings } from 'src/app/surveys/Survey';
import styles from '../SiteMenu.module.scss';

const SurveyMenu = () => {
    const isOpen = useAppSelector(getIsSiteMenuOpen);
    const appRoutesAvailability = useAppSelector(getAppRoutesAvailability);
    const surveyRoutesAvailability = useAppSelector(getSurveyRoutesAvailability);

    const navigate = useNavigate();
    const { pathname } = useLocation();

    if (!appRoutesAvailability.survey) {
        return null;
    }

    const getClassNames = (menuItemPath: string) => {
        const itemPath = '/survey' + menuItemPath;
        return classNames(styles.menuItem, {
            [styles.menuItemOpen]: isOpen,
            [styles.menuItemActive]: pathname === itemPath || pathname + '/' === itemPath,
        });
    };

    const onMenuClick = (path: string) => {
        navigate('/survey' + path);
    };

    return (
        <>
            {surveyRoutesAvailability.surveyDesignSummary && (
                <div
                    className={getClassNames(surveyRouteSettings.surveyDesignSummary.path)}
                    onClick={() => onMenuClick(surveyRouteSettings.surveyDesignSummary.path)}
                >
                    <div className={styles.icon}>
                        <ContentPasteSearchIcon />
                    </div>
                    <div className={styles.label}>Survey design</div>
                </div>
            )}
        </>
    );
};

export default SurveyMenu;
