import React from 'react';
import useProject from 'src/shared/hooks/useProject';
import IucnMetrics from '../metrics/iucn-metrics/IucnMetrics';
import BacterialFunctionalDiversityMetrics from '../metrics/bacterial-functional-diversity/BacterialFunctionalDiversityMetrics';
import EvolutionaryMetrics from '../metrics/evolutionary-metrics/EvolutionaryMetrics';
import SpeciesRichnessMetrics from '../metrics/species-richness-metrics/SpeciesRichnessMetrics';
import CommunityComparisonMetric from '../metrics/community-comparison-metric/CommunityComparisonMetric';

import styles from './SingleTimepointMetricsOverview.module.scss';
import FungalFunctionalDiversityMetrics from '../metrics/fungal-functional-diversity/FungalFunctionalDiversityMetrics';

const SingleTimepointMetricsOverview = () => {
    const { isMetricAvailable } = useProject();

    const hasBFDMetric = isMetricAvailable({ featureKey: 'bfd' });
    const hasFFDMetric = isMetricAvailable({ featureKey: 'ffd' });

    return (
        <div className={styles.singleTimepointMetricsOverview}>
            <SpeciesRichnessMetrics isSingleTimepoint={true} />
            <EvolutionaryMetrics isSingleTimepoint={true} />
            {!hasBFDMetric && <IucnMetrics isSingleTimepoint={true} />}
            {hasBFDMetric && <BacterialFunctionalDiversityMetrics isSingleTimepoint={true} />}
            {hasFFDMetric && <FungalFunctionalDiversityMetrics isSingleTimepoint={true} />}
            <CommunityComparisonMetric />
        </div>
    );
};

export default SingleTimepointMetricsOverview;
