import { gql } from 'graphql-request';
import { TaxonomySummary } from 'src/shared/types/graph/project';

export const taxonomicComparisonQuery = gql`
    query getSamples($projectId: String!, $samples: sampleFilter) {
        taxonomicComparisonData: project(projectId: $projectId, samples: $samples) {
            taxonomySummary
        }
    }
`;

type TaxonomicComparison = {
    taxonomySummary: TaxonomySummary;
};

export type TaxonomicComparisonQueryResult = {
    taxonomicComparisonData: TaxonomicComparison;
};
