import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import useAuthService from 'src/shared/hooks/useAuthService';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import Loader from 'src/shared/components/loader/Loader';

import styles from './Signin.module.scss';
import PasswordInput from 'src/shared/components/password-input/PasswordInput';
import Input from 'src/shared/components/input/Input';
import { PrimaryButton } from 'src/shared/components/button/Button';

const Signin = () => {
    const { isAuthenticated, isAuthenticating, hasGivenTncConsent, signin } = useAuthService();
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [emailErrorMessage, setEmailErrorMessage] = useState('');
    const [passwordErrorMessage, setPasswordErrorMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState<string | JSX.Element>('');

    useEffect(() => {
        if (isAuthenticated && !isAuthenticating && hasGivenTncConsent) {
            navigate('/customers');
        }
    }, [isAuthenticated, isAuthenticating]);

    if (isAuthenticating) {
        return <Loader />;
    }

    const handleErrors = () => {
        let validEmail = false,
            validPwd = false;
        const isValidEmail = /^[\w-\.\+]+@([\w-]+\.)+[\w-]{2,8}$/g;
        if (email && email.match(isValidEmail)) {
            setEmailErrorMessage('');
            validEmail = true;
        } else {
            if (!email) {
                setEmailErrorMessage('Please enter email');
            } else {
                setEmailErrorMessage('The email you have entered is incorrect');
            }
        }
        if (password === '') {
            setPasswordErrorMessage('Please enter password');
        } else {
            setPasswordErrorMessage('');
            validPwd = true;
        }
        return { validEmail, validPwd };
    };

    const userDisabledMessage = (
        <>
            Your account is currently deactivated.
            <br />
            <br />
            To regain access to the NatureMetrics platform, your organisation&apos;s administrator will need to reactivate your account.
        </>
    );

    const handleSignin = (e: React.BaseSyntheticEvent) => {
        e.preventDefault();
        const validationError = handleErrors();
        if (validationError?.validEmail && validationError?.validPwd) {
            signin({
                email,
                password,
                onFailure: (e: Error) => {
                    console.error(e);
                    const messageContent = e.message === 'User is disabled.' ? userDisabledMessage : e.message;
                    setErrorMessage(messageContent);
                },
            });
        }
    };

    return (
        <>
            <div className={styles.title}>Welcome!</div>
            <div className={styles.subTitle}>Sign in with your username and password</div>

            <form className={styles.signinForm} onSubmit={handleSignin}>
                <Input
                    name='email'
                    autoComplete='email'
                    placeholder='Email'
                    onChange={e => setEmail(e.target.value)}
                    value={email}
                    hasError={Boolean(emailErrorMessage)}
                />

                <PasswordInput
                    name='password'
                    value={password}
                    placeholder='Password'
                    hasError={Boolean(passwordErrorMessage)}
                    onChange={e => setPassword(e.target.value)}
                />

                {(errorMessage || emailErrorMessage || passwordErrorMessage) && (
                    <Stack sx={{ width: '100%' }} spacing={2}>
                        <Alert severity='error' sx={{ color: '#f00' }}>
                            {errorMessage || emailErrorMessage || passwordErrorMessage}
                        </Alert>
                    </Stack>
                )}
                <span className={styles.forgotPasswordLink} onClick={() => navigate('/login/forgot-password')}>
                    Forgot password?
                </span>

                <div className={styles.loginButton}>
                    <PrimaryButton onClick={handleSignin}>Sign In</PrimaryButton>
                </div>
            </form>
        </>
    );
};
export default Signin;
