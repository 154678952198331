import graphApiSlice from 'src/shared/state/api-slices/graphApiSlice';

import {
    CreateSurveyDesignQueryResult,
    createSurveyDesignQuery,
    SurveyDesignCompletionQueryResult,
    surveyDesignCompletionQuery,
} from './queries/newSurveyDesignQuery';
import { SamplingEvent, ProgressStatus } from 'src/shared/types/graph/project';
import { CMSHabitatAssayTypeWithoutImage } from 'src/shared/types/cms/habitatAssayType';
import { SurveyDesignSample } from '../newSurveyDesignSlice';
import { SurveySamplingCadenceType } from 'src/shared/types/graph/samples';

type HasSurveyDesignQueryParams = {
    projectId: string;
};

type CreateSurveyDesignParams = {
    customerId: string;
    projectName: string;
    projectId: string;
    projectCode: string;
    projectType: string;
    subscriptionType: string;
    surveyPublishDate: string;
    country: string;
    speciesOfInterest: string[];
    area: string;
    samplingCadenceType: SurveySamplingCadenceType;
    samplingEvent: SamplingEvent[];
    samplingEventsPerYear: number;
    habitatAssayTypes: CMSHabitatAssayTypeWithoutImage[];
    projectLeadName: string;
    projectLeadEmail: string;
    projectLeadRole: string;
    sampleGroups: string[];
    targetOutcome: string;
    projectOutline: string;
    status: ProgressStatus;
    surveySamples: SurveyDesignSample[];
};

const newSurveyDesignGraphSlice = graphApiSlice.injectEndpoints({
    endpoints: builder => ({
        hasSurveyDesign: builder.query<SurveyDesignCompletionQueryResult, HasSurveyDesignQueryParams>({
            query: params => ({
                body: surveyDesignCompletionQuery,
                variables: params,
            }),
        }),
        createSurveyDesign: builder.mutation<CreateSurveyDesignQueryResult, CreateSurveyDesignParams>({
            query: params => ({
                body: createSurveyDesignQuery,
                variables: { input: params },
            }),
        }),
    }),
});

export const { useCreateSurveyDesignMutation, useHasSurveyDesignQuery } = newSurveyDesignGraphSlice;
