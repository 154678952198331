import React, { CSSProperties } from 'react';
import styles from './Chip.module.scss';

export type ChipProps = {
    label: string;
    color?: string;
};

const Chip = (props: ChipProps) => {
    const { label, color = '#076769' } = props;

    return (
        <div className={styles.chip} style={{ '--chip-background-color': color } as CSSProperties}>
            {label}
        </div>
    );
};

export default Chip;
