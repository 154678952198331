import React from 'react';

import classNames from 'classnames';
import CustomerAdminIcon from '@mui/icons-material/WorkspacePremium';

import { UserType } from 'src/shared/types/user';
import UserTypeTag from '../user-type-tag/UserTypeTag';

import styles from './UserDetails.module.scss';

type UserDetailsProps = {
    firstName: string;
    lastName: string;
    email: string;
    status: string;
    phone?: string;
    userType?: UserType;
    isCustomerAdmin?: boolean;
    contactInfoClass?: string;
    isAssignedToOrganisation?: boolean;
};

const UserDetails = ({ firstName, lastName, email, status, phone, userType, isCustomerAdmin, contactInfoClass }: UserDetailsProps) => {
    const userNameClass = classNames(styles.name, styles['name' + status]);

    const contactSectionClass = classNames(styles.contact, styles['contact' + status], contactInfoClass);

    return (
        <div className={styles.userDetails}>
            <div className={styles.avatar}>{`${firstName[0]}${lastName[0]}`}</div>
            <div className={styles.info}>
                <div className={userNameClass}>{`${firstName} ${lastName}`}</div>
                <div className={contactSectionClass}>{email}</div>
                {phone && <div className={contactSectionClass}>{phone}</div>}
                {(userType || isCustomerAdmin) && (
                    <div className={styles.icons}>
                        {userType && <UserTypeTag userType={userType} />}
                        {isCustomerAdmin && (
                            <div className={styles.adminIcon}>
                                <CustomerAdminIcon className={styles.customerAdminLogo} /> Admin
                            </div>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
};

export default UserDetails;
