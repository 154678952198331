import classNames from 'classnames';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './Breadcrumbs.module.scss';
import Link from '@mui/material/Link';

export type BreadcrumbsProps = {
    breadcrumbs: Breadcrumb[];
    className?: string;
};
export type Breadcrumb = { label: string; path?: string };

const Breadcrumbs = ({ breadcrumbs, className }: BreadcrumbsProps) => {
    const navigate = useNavigate();
    const breadcrumbsClassNames = classNames(styles.breadcrumbs, className);

    return (
        <div className={breadcrumbsClassNames} role='navigation'>
            {breadcrumbs.map((item, index) => {
                return (
                    <span key={index}>
                        {item.path ? (
                            <Link className={styles.pathLink} onClick={() => navigate(`${item.path}`)}>
                                {item.label}
                            </Link>
                        ) : (
                            <span>{item.label}</span>
                        )}
                        {index !== breadcrumbs.length - 1 && ' / '}
                    </span>
                );
            })}
        </div>
    );
};

export default Breadcrumbs;
