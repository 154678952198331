import { useNavigate } from 'react-router-dom';
import { noop } from 'lodash';
import authService, { UserGroup } from 'src/services/auth-service';
import { useAppDispatch } from 'src/store';
import { batch } from 'react-redux';
import { resetCustomers } from 'src/app/customers/state/customersSlice';
import { resetProjects } from 'src/app/projects/views/project-list/state/projectListSlice';
import { useState } from 'react';
import { resetRoutes } from 'src/routes/state/routesSlice';
import loginStorageService from 'src/app/login/services/login-storage-service';
import { useLazyGetUserPermissionsQuery } from 'src/app/login/state/api/loginGraphSlice';

type SigninParams = {
    email: string;
    password: string;
    onFailure: (e: Error) => void;
};

type ChangePasswordParams = {
    password: string;
    onFailure: (message: string) => void;
};

type VerifyCustomChallengeCodeParams = Pick<SigninParams, 'onFailure'> & {
    verificationCode: string;
};

const useAuthService = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const [isAuthenticating, setIsAuthenticating] = useState(authService.getIsRefreshing());
    const [fetchPermissions] = useLazyGetUserPermissionsQuery();

    const checkAuthenticationStatus = () => {
        if (authService.getIsRefreshing()) {
            setTimeout(checkAuthenticationStatus, 100);
        } else if (isAuthenticating) {
            setIsAuthenticating(false);
        }
    };
    checkAuthenticationStatus();

    const onSigninSuccess = async () => {
        // Adding timeout so that the right token is set to backend
        await new Promise(resolve => setTimeout(resolve, 300));

        const permissions = await fetchPermissions(undefined).unwrap();

        loginStorageService.setTermsOfUseConsent(false);
        if (!permissions.myAppPermission.tnc_consent) {
            navigate('/login/terms-of-use');
            return;
        }
        loginStorageService.setTermsOfUseConsent(true);
        navigate('/customers');
    };

    const signin = (params: SigninParams): void => {
        const { email, password, onFailure } = params;

        authService.signin({
            email,
            password,
            onSuccess: onSigninSuccess,
            onFailure: onFailure,
            newPasswordRequired: () => {
                navigate('/login/set-new-password');
            },
            customChallenge: () => {
                navigate('/login/verify-custom-challenge');
            },
        });
    };

    const signout = () => {
        authService.signout(() => {
            batch(() => {
                dispatch(resetCustomers());
                dispatch(resetProjects());
                dispatch(resetRoutes());
            });
        });
    };

    const changePassword = (params: ChangePasswordParams) => {
        const { password, onFailure } = params;

        authService.completeNewPasswordChallenge({
            password: password,
            onSuccess: () => {
                //calling this for first time password reset to enable custom auth challenge
                signin({
                    email: authService.getCognitoUser()?.getUsername() || '',
                    password,
                    onFailure: noop,
                });
            },
            onFailure: error => {
                onFailure(error.message);
            },
            customChallenge: () => {
                navigate('/login/verify-phone-number');
            },
        });
    };

    const isCurrentUserAuthenticated = (): boolean => {
        const cognitoUser = authService.getCognitoUser();
        const cognitoSession = authService.getCognitoUserSession();
        const hasGivenTncConsent = loginStorageService.getTermsOfUseConsent();

        // If there is no session, just return false
        if (!cognitoUser || !cognitoSession) {
            return false;
        }

        if (isAuthenticating) {
            return true;
        }

        // Conside the user unAuthenticated unless the tnc consent is given
        if (!hasGivenTncConsent) {
            return false;
        }

        // // If there is a session but expired, try to refresh the token
        // // If the refresh failed, authservice will redirect the user to "/"
        if (!cognitoSession.isValid()) {
            authService.refreshSession();
        }

        // The use is authenticated and the session is valid
        return true;
    };

    const redirectIfNotAuthenticated = () => {
        if (!isCurrentUserAuthenticated()) {
            navigate('/');
        }
    };

    const getCurrentUserData = () => {
        return authService.getCurrentUserData();
    };

    const verifyCustomChallengeCode = (params: VerifyCustomChallengeCodeParams) => {
        const { verificationCode, onFailure } = params;

        return authService.verifyCustomChallenge({
            onSuccess: onSigninSuccess,
            onFailure: onFailure,
            verificationCode: verificationCode,
        });
    };

    const resendCustomChallengeCode = (params: { onFailure: (e: Error) => void; onSuccess: () => void }) => {
        return authService.resendCustomChallengeCode(params);
    };

    const isCurrentUserOnboardingManager = (): boolean => {
        const currentUserData = getCurrentUserData();

        if (!currentUserData) {
            return false;
        }

        // Check to prioritise Super Admin role over Onboarding Manager if both are present in the user group
        if (currentUserData?.isAdmin && currentUserData?.groups.includes(UserGroup.SUPER_ADMIN)) {
            return false;
        }

        const hasCorrectGroup = currentUserData?.groups && currentUserData?.groups.includes(UserGroup.ONBOARDING_MANAGER);

        return currentUserData?.isAdmin && hasCorrectGroup;
    };

    const isCurrentUserSuperAdmin = (): boolean => {
        const currentUserData = getCurrentUserData();

        if (!currentUserData) {
            return false;
        }

        const hasCorrectGroup = currentUserData?.groups && currentUserData?.groups.includes(UserGroup.SUPER_ADMIN);

        return currentUserData?.isAdmin && hasCorrectGroup;
    };

    return {
        currentUser: getCurrentUserData(),
        cognitoUser: authService.getCognitoUser(),
        cognitoSession: authService.getCognitoUserSession(),
        isAuthenticated: isCurrentUserAuthenticated(),
        isAuthenticating,
        isCurrentUserOnboardingManager: isCurrentUserOnboardingManager(),
        isCurrentUserSuperAdmin: isCurrentUserSuperAdmin(),
        hasGivenTncConsent: loginStorageService.getTermsOfUseConsent() || false,
        customChallengeParameters: authService.getCustomChallangeParameters(),
        redirectIfNotAuthenticated,
        signin,
        onSigninSuccess,
        signout,
        changePassword,
        verifyCustomChallengeCode,
        resendCustomChallengeCode,
    };
};

export default useAuthService;
