import { gql } from 'graphql-request';
import { Sample } from 'src/shared/types/graph/samples';

export const sampleComparisonQuery = gql`
    query getSamples($projectId: String!, $samples: sampleFilter) {
        sampleComparisonData: project(projectId: $projectId, samples: $samples) {
            samples {
                sampleId
                sampleReceivedDate
                metrics
                covariates
            }
        }
    }
`;

type SampleComparisonData = {
    samples: Pick<Sample, 'sampleId' | 'sampleReceivedDate' | 'metrics' | 'covariates'>[];
};

export type SampleComparisonQueryResult = {
    sampleComparisonData: SampleComparisonData;
};
