import React, { ReactNode, useEffect, useRef } from 'react';
import * as d3 from 'd3';

import styles from './Hexagon.module.scss';
import uniqueId from 'lodash/uniqueId';

type HexagonProps = {
    score: number;
    content: ReactNode;
    fixedColours?: [string, string];
    thickness?: number;
    size?: number;
};
const SIZE = 220;

const polarToCartesian = (centerX: number, centerY: number, radius: number, angleInDegrees: number) => {
    const angleInRadians = ((angleInDegrees - 90) * Math.PI) / 180.0;

    return {
        x: centerX + radius * Math.cos(angleInRadians),
        y: centerY + radius * Math.sin(angleInRadians),
    };
};

const getArc = (x: number, y: number, radius: number, startAngle: number, endAngle: number) => {
    const start = polarToCartesian(x, y, radius, endAngle);
    const end = polarToCartesian(x, y, radius, startAngle);

    const largeArcFlag = endAngle - startAngle <= 180 ? '0' : '1';

    const d = ['M', start.x, start.y, 'A', radius, radius, 0, largeArcFlag, 0, end.x, end.y].join(' ');

    return d;
};

const Hexagon = (props: HexagonProps) => {
    const { score, content, fixedColours = ['#129b9e', '#076769'], thickness = 10, size = SIZE } = props;

    const randomGradientId = uniqueId();

    const halfSize = size / 2;
    const d3Container = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        if (d3Container.current !== null) {
            const scale = d3.scaleLinear().domain([0, 100]).range([0, 359.999]);

            const scoreAngle = scale(score);

            (d3Container?.current as any).querySelector('#arc1').setAttribute('d', getArc(halfSize, halfSize, halfSize, 0, scoreAngle));
            (d3Container?.current as any)
                .querySelector('#arc3')
                .setAttribute('d', getArc(halfSize, halfSize, halfSize, 0, scoreAngle > 0 ? scoreAngle + 2 : 0));
        }
    }, [score, halfSize]);

    const hexagonPoints = [0, 1, 2, 3, 4, 5, 6].map((_, i) => {
        const radius = size / 2 - thickness / 2;
        const angleDegree = 60 * i - 30;
        const angleRadius = (Math.PI / 180) * angleDegree;
        return [size / 2 + radius * Math.cos(angleRadius), size / 2 + radius * Math.sin(angleRadius)];
    });

    const hexagonPath = d3.path();

    hexagonPoints.forEach((point, index) => {
        if (index === 0) {
            hexagonPath.moveTo(point[0], point[1]);
        } else {
            hexagonPath.lineTo(point[0], point[1]);
        }
    });
    hexagonPath.closePath();

    return (
        <div ref={d3Container} className={styles.hexagonContainer} style={{ width: size, height: size }}>
            <div className={styles.content}>{content}</div>
            <svg width={size} height={size} viewBox={`0 0 ${size} ${size}`} fill='none' xmlns='http://www.w3.org/2000/svg'>
                <mask id='mask1' style={{ maskType: 'alpha' }}>
                    <path d={hexagonPath.toString()} stroke='#ECECEC' strokeLinejoin='round' strokeWidth={thickness} />
                </mask>
                <g mask='url(#mask1)'>
                    <path d={hexagonPath.toString()} stroke='#ECECEC' strokeLinejoin='round' strokeWidth={thickness} />
                    <path id='arc3' stroke='#000' strokeWidth={size} />
                    <path id='arc1' stroke={`url(#gradient_${randomGradientId})`} strokeWidth={size} />
                </g>

                <path d={hexagonPath.toString()} fill='#FFF' strokeWidth={thickness} />
                <defs>
                    <linearGradient id={`gradient_${randomGradientId}`} gradientUnits='objectBoundingBox'>
                        <stop stopColor={fixedColours[1]} />
                        <stop stopColor={fixedColours[0]} />
                        <stop offset='1' stopColor={fixedColours[1]} />
                    </linearGradient>
                </defs>
            </svg>
        </div>
    );
};

export default Hexagon;
