import apiService from './api-service';

import { CMSImage } from 'src/shared/types/cms/image';
import { CMSSpecies } from 'src/shared/types/cms/species';
import config from 'src/config';

type GetHighlightedSpeciesProps = {
    projectId: string;
    habitat: string;
    assay: string;
};

type GetHighlightedSpeciesResponse = {
    data: {
        attributes: {
            species: {
                data: {
                    attributes: CMSSpecies;
                };
            };
        };
    }[];
};

type GetAllHabitatAssayTypesResponse = {
    data: {
        attributes: {
            image: CMSImage;
            habitatAssayKey: string;
            habitat: {
                data: {
                    attributes: {
                        habitatName: string;
                    };
                };
            };
            assay: {
                data: {
                    attributes: {
                        assayName: string;
                    };
                };
            };
        };
    }[];
};

class CMSService {
    public async getArticleBySlug(slug: string): Promise<{ content: string; title: string } | null> {
        try {
            const response = await apiService.fetch(`${config.cmsApiUrl}/articles/slug/${slug}`);
            if (!response?.data?.attributes.content) {
                return null;
            }
            return {
                content: response?.data?.attributes.content,
                title: response?.data?.attributes.title,
            };
        } catch (error: any) {
            if (error.status !== 404) {
                console.error('Failed to fetch  content:', error);
            }
            return null;
        }
    }

    public async getSpeciesByScientificName(scientificName: string): Promise<CMSSpecies | null> {
        try {
            const response = await apiService.fetch(`${config.cmsApiUrl}/species/scientific_name/${scientificName}?populate=*`);

            if (!response.data) {
                return null;
            }

            return {
                ...response?.data?.attributes,
                image: this.processImageURLs(response?.data?.attributes?.image),
            };
        } catch (e) {
            // Don't throw an error as we may not be having any details about this species
            return null;
        }
    }

    public async getHighlightedSpecies(props: GetHighlightedSpeciesProps): Promise<CMSSpecies | null> {
        const { projectId, habitat, assay } = props;
        try {
            const response = (await apiService.fetch(
                `${process.env.REACT_APP_CMS_API_URL}/` +
                    `projects-species-highlights?` +
                    `filters[project][projectId][$eq]=${projectId}&` +
                    `filters[habitat][habitatId][$eq]=${habitat}&` +
                    `filters[assay][assayId][$eq]=${assay}&` +
                    `populate=species`
            )) as GetHighlightedSpeciesResponse;
            const speciesScientificName = response?.data?.[0]?.attributes?.species.data.attributes.scientific_name;

            if (!speciesScientificName) {
                return null;
            }

            return this.getSpeciesByScientificName(speciesScientificName);
        } catch (e) {
            throw e;
        }
    }

    public async getAllHabitatAssayTypes() {
        try {
            const response = (await apiService.fetch(
                `${process.env.REACT_APP_CMS_API_URL}/` + `habitat-and-assay-types?` + `populate=*&pagination[pageSize]=999`
            )) as GetAllHabitatAssayTypesResponse;

            return response?.data?.map(entry => {
                const habitatName = entry.attributes.habitat.data.attributes.habitatName;
                const assayName = entry.attributes.assay.data.attributes.assayName;
                const habitatAssayKey = entry.attributes.habitatAssayKey;

                return {
                    habitatName,
                    assayName,
                    habitatAssayKey,
                    image: this.processImageURLs(entry.attributes?.image),
                };
            });
        } catch (e) {
            throw e;
        }
    }

    public substituteArticlePlaceholders(content: string, substitutions: { [key: string]: string }) {
        const keys = Object.keys(substitutions);

        keys.forEach(key => {
            content = content.replaceAll(`[[${key}]]`, substitutions[key]);
        });

        return content;
    }

    private processImageURLs(image: CMSImage) {
        if (!image || !image.data?.attributes?.formats) {
            return {};
        }

        const large = image.data.attributes.formats.large?.url;
        const medium = image.data.attributes.formats.medium?.url;
        const small = image.data.attributes.formats.small?.url;
        const thumbnail = image.data.attributes.formats.thumbnail?.url;

        return {
            large: large || medium || small || thumbnail,
            medium: medium || large || small || thumbnail,
            small: small || medium || large || thumbnail,
            thumbnail: thumbnail || small || medium || large,
        };
    }

    public async getProjectById(projectId: string): Promise<any | null> {
        try {
            const response = await apiService.fetch(`${process.env.REACT_APP_CMS_API_URL}/projects?filters[projectId][$eq]=${projectId}`);
            return {
                isDemo: response?.data[0]?.attributes?.isDemo || false,
            };
        } catch (error: any) {
            if (error.status !== 404) {
                console.error('Failed to fetch  content:', error);
            }
            return {
                isDemo: false,
            };
        }
    }
}

export default new CMSService();
