import React, { useEffect } from 'react';
import FishMetricsChart from './components/fish-metrics-chart/FishMetricsChart';
import FishIndexBackground from 'src/assets/images/dashboard/fish_background.webp';
import { useFishMetricsQuery } from '../../../state/metrics/metricsGraphSlice';
import useProject from 'src/shared/hooks/useProject';
import styles from './FishMetrics.module.scss';
import CMSArticle from 'src/shared/components/cms-article/CMSArticle';
import FishMetricsSingleChart from './components/firsh-metric-single-chart/FishMetricsSingleChart';

const FishMetrics = ({ isSingleTimepoint }: { isSingleTimepoint: boolean }) => {
    const {
        currentProjectSelectedDateRange,
        currentProjectId,
        isMetricAvailable,
        currentProjectSelectedHabitatAssayType: habitatAssayType,
    } = useProject();

    const isFishMetricsAvailable = isMetricAvailable({ featureKey: 'fishcommercialvalue' });

    const { currentData: fishMetrics, isFetching } = useFishMetricsQuery(
        {
            projectId: currentProjectId || '',
            fromDate: currentProjectSelectedDateRange?.startDate,
            toDate: currentProjectSelectedDateRange?.endDate,
            habitatAssay: habitatAssayType?.key || '',
        },
        {
            skip: !isFishMetricsAvailable,
        }
    );

    useEffect(() => {
        if (isFetching) {
            return;
        }
    }, [isFetching]);

    if (isFetching || !fishMetrics || !Object.values(fishMetrics).every(Boolean)) {
        return null;
    }
    return isSingleTimepoint ? (
        <FishMetricsSingleChart data={fishMetrics} />
    ) : (
        <div>
            <div className={styles.backDrop}>
                <img src={FishIndexBackground} alt='' />
            </div>
            <div className={styles.fishMetric}>
                <div className={styles.header}>Marine fish metrics</div>
                <div className={styles.description}>
                    <CMSArticle slug='fish-metric-chart-description' />
                </div>

                <div className={styles.midContent}>
                    <div className={styles.chart}>
                        <div className={styles.graph}>
                            <FishMetricsChart data={fishMetrics} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FishMetrics;
