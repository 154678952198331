import React from 'react';
import { CMSHabitatAssayType } from 'src/shared/types/cms/habitatAssayType';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import styles from './MarkerInfo.module.scss';

type MarkerInfoProps = {
    sample: {
        habitatAssayTypes: Pick<CMSHabitatAssayType, 'habitatName' | 'assayName' | 'habitatAssayKey'>[];
        kitType: string;
        latitude: string;
        longitude: string;
        sampleGroup: string;
        sampleId: string;
        samplePoint: string;
    };
};

const MarkerInfo = (props: MarkerInfoProps) => {
    const { sample } = props;
    return (
        <div className={styles.markerTooltip}>
            <div className={styles.topContent}>
                <div className={styles.sampleId}>{sample.sampleId}</div>
                <div className={styles.location}>
                    <span>{`${sample.latitude},${sample.longitude}`}</span>
                    <FiberManualRecordIcon style={{ fontSize: '8px' }} /> <span>{`Sample point ${sample.samplePoint}`}</span>
                </div>
            </div>
            <span className={styles.divider} />
            <div className={styles.bottomContent}>
                <div>
                    <span className={styles.label}>KitType:</span>
                    &nbsp;{sample.kitType}
                </div>
                <div>
                    <span className={styles.label}>Test Types:</span>
                </div>
                <div className={styles.habitatAssayTypes}>
                    {sample.habitatAssayTypes.map((entry, index) => {
                        return (
                            <div key={index} className={styles.habitatAssayTypeTag}>
                                <div key={index} className={styles.habitatAssayTypeTagValue}>
                                    <div className={styles.habitatName}>{entry.habitatName}</div>
                                    <div className={styles.assayName}>{entry.assayName}</div>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
};

export default MarkerInfo;
