import { TimeSeriesData, TimeSeriesChartData } from 'src/shared/types/graph/chartData';

export const transformData = (input: TimeSeriesData[], getColourForArea: (area: string) => string): TimeSeriesChartData[] => {
    const output: TimeSeriesChartData[] = [];
    input.forEach(inputObj => {
        inputObj.group.map(groupObj => {
            let outputObj = output.find(o => o.type === groupObj.type);
            if (!outputObj) {
                outputObj = {
                    type: groupObj.type,
                    data: [],
                    color: getColourForArea(groupObj.type),
                    label: groupObj.type,
                };
                output.push(outputObj);
            }

            outputObj.data.push({
                date: parseInt(inputObj.datetime),
                value: groupObj.value,
            });
        });
    });

    return output;
};
