import React from 'react';
import moment from 'moment';
import FishMetrics from './fish-metrics/FishMetrics';
import SpeciesRichnessMetrics from './species-richness-metrics/SpeciesRichnessMetrics';
import IucnMetrics from './iucn-metrics/IucnMetrics';
import EvolutionaryMetrics from './evolutionary-metrics/EvolutionaryMetrics';
import BacterialFunctionalDiversityMetrics from './bacterial-functional-diversity/BacterialFunctionalDiversityMetrics';
import SpeciesHighlight from './species-highlight/SpeciesHighlight';
import useProject from 'src/shared/hooks/useProject';
import SingleTimepointMetricsOverview from '../single-timepoint-metrics-overview/SingleTimepointMetricsOverview';
import { ErrorMessageWithBackground } from 'src/shared/components/error-msg/ErrorMessage';
import styles from './Metrics.module.scss';
import FungalFunctionalDiversityMetrics from './fungal-functional-diversity/FungalFunctionalDiversityMetrics';

export const Metrics = () => {
    const { currentProjectSelectedHabitatAssayType, currentProjectSelectedDateRange, isMetricAvailable } = useProject();
    const isSingleTimepoint = currentProjectSelectedHabitatAssayType?.singleTimepoint || false;

    const hasFishMetrics = isMetricAvailable({ featureKey: 'fishcommercialvalue' });
    const hasBFDMetrics = isMetricAvailable({ featureKey: 'bfd' });
    const hasFFDMetrics = isMetricAvailable({ featureKey: 'ffd' });

    const speciesHighlight = <SpeciesHighlight isFishMetricsPresent={hasFishMetrics} isSingleTimepoint={isSingleTimepoint} />;

    const iucnMetric = !hasBFDMetrics && (
        <div className={styles.iucnMetrics}>
            <IucnMetrics hideDateFilter={isSingleTimepoint} />
        </div>
    );

    const fishMetric = hasFishMetrics ? (
        <div className={styles.fishIndex}>
            <FishMetrics isSingleTimepoint={isSingleTimepoint} />
        </div>
    ) : null;

    const otherMetrics = (
        <div>
            {hasFishMetrics ? (
                <>
                    {!isSingleTimepoint ? (
                        <div className={styles.fishMetricAndSpeciesHighlight}>
                            {fishMetric}
                            {speciesHighlight}
                        </div>
                    ) : (
                        <>
                            {fishMetric}
                            {speciesHighlight}
                        </>
                    )}
                    {iucnMetric}
                </>
            ) : (
                <>
                    {iucnMetric}
                    {speciesHighlight}
                </>
            )}
        </div>
    );

    const selectedFromDate = moment(currentProjectSelectedDateRange?.startDate);
    const selectedToDate = moment(currentProjectSelectedDateRange?.endDate);
    return (
        <div className={styles.metricsContainer}>
            {selectedToDate.isBefore(selectedFromDate) && (
                <ErrorMessageWithBackground
                    message={`Adjust the date range above so the 'Displaying from' date is earlier than the 'to' date.`}
                />
            )}
            {isSingleTimepoint && <SingleTimepointMetricsOverview />}
            {!isSingleTimepoint && (
                <div className={styles.metrics}>
                    <SpeciesRichnessMetrics />
                    <EvolutionaryMetrics />
                    {hasBFDMetrics && <BacterialFunctionalDiversityMetrics />}
                    {hasFFDMetrics && <FungalFunctionalDiversityMetrics />}
                </div>
            )}
            {otherMetrics}
        </div>
    );
};

export default Metrics;
