import { gql } from 'graphql-request';
import { UserPermissions } from 'src/shared/types/graph/user';

export const updateUserDetailsMutation = gql`
    mutation updateUserDetails($tncConsent: Boolean, $cognitoId: String!, $phone: String, $firstName: String, $lastName: String) {
        updateMyDetail(tnc_consent: $tncConsent, cognitoId: $cognitoId, firstName: $firstName, lastName: $lastName, phone: $phone)
    }
`;

export type UpdateUserDetailsMutationResult = {
    user: { cognitoId: string };
};

export const getUserPermissions = gql`
    query userPermissions {
        myAppPermission {
            tnc_consent
            is_internal_user
            customerLevelPermissions {
                customerId
                roles
                permissions {
                    view
                    create
                    update
                    delete
                }
            }
            projectLevelPermission {
                projectId
                roles
                permissions {
                    view
                    create
                    update
                    delete
                }
            }
        }
    }
`;

export type GetUserPermissionsResult = {
    myAppPermission: UserPermissions;
};
