import React from 'react';
import { UserType } from 'src/shared/types/user';

import styles from './UserTypeTag.module.scss';

type UserTypeTagProps = {
    userType: UserType;
};

const UserTypeTag = ({ userType }: UserTypeTagProps) => {
    return <div className={`${styles.userTypeTag} ${styles['userTypeTag' + userType]}`}>{userType}</div>;
};

export default UserTypeTag;
