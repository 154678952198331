import { Action, createSlice, PayloadAction, ThunkAction } from '@reduxjs/toolkit';
import { RootState } from 'src/store';
import { CMSHabitatAssayTypeWithoutImage } from 'src/shared/types/cms/habitatAssayType';
import { ProjectDefinitionCompletionStatus, SamplingEvent } from 'src/shared/types/graph/project';
import { SurveySamplingCadenceType } from 'src/shared/types/graph/samples';

export type SurveyDesignSample = {
    sampleId: string;
    samplePoint: string;
    latitude: string;
    longitude: string;
    sampleGroup: string;
    kitType: string;
    habitatAssayTypes: CMSHabitatAssayTypeWithoutImage[];
};

export type NewSurveyDesignFormState = {
    // Basic information
    projectId: string;
    projectName: string;
    projectCode: string;
    subscriptionType: string;
    country: string;
    area: string;
    surveyDesignCompletionStatus: ProjectDefinitionCompletionStatus | null;
    surveyPublishDate: string;

    // Survey design contact
    projectLeadName: string;
    projectLeadEmail: string;
    projectLeadRole: string;

    // Survey aims
    projectType: string;
    targetOutcomes: string;
    projectOutline: string;

    // Sampling cadence
    samplingCadence: SurveySamplingCadenceType | string;
    samplingEvents: SamplingEvent[];
    samplingEventsPerYear: number;
    sampleGroups: string[];

    // Species of interest
    speciesOfInterest: string[];

    // Habitat assay types
    habitatAssayTypes: CMSHabitatAssayTypeWithoutImage[];

    // Survey samples
    uploadedFileContent: string;
    uploadedFileErrors: string[];
    surveySamples: SurveyDesignSample[];
};

export const defaultSurveyDesignFormState = {
    projectId: '',
    projectName: '',
    projectCode: '',
    subscriptionType: 'Survey',
    surveyPublishDate: '',
    country: '',
    area: '',
    projectLeadRole: '',
    projectLeadName: '',
    projectLeadEmail: '',
    projectType: '',
    projectOutline: '',
    targetOutcomes: '',
    samplingCadence: '',
    samplingEventsPerYear: 0,
    samplingEvents: [],
    sampleGroups: [],
    speciesOfInterest: [],
    habitatAssayTypes: [],
    surveyDesignCompletionStatus: null,
    uploadedFileContent: '',
    uploadedFileErrors: [],
    surveySamples: [],
};

export type NewSurveyDesignUIState = {
    formStep: number;
    formState: NewSurveyDesignFormState;
};

type SurveyDesignState = {
    uiState: NewSurveyDesignUIState;
};

const initialState: SurveyDesignState = {
    uiState: { formStep: 0, formState: { ...defaultSurveyDesignFormState } },
};

export const resetSurveyDesign = (): ThunkAction<void, RootState, void, Action<string>> => dispatch => {
    dispatch(newSurveyDesignSlice.actions.reset());
};

const newSurveyDesignSlice = createSlice({
    name: 'newSurveyDesign',
    initialState,
    reducers: {
        setCurrentStep(state, action: PayloadAction<number>) {
            state.uiState.formStep = action.payload;
        },
        setFormState(state, action: PayloadAction<Partial<NewSurveyDesignFormState>>) {
            state.uiState.formState = { ...state.uiState.formState, ...action.payload };
        },

        reset(state) {
            // eslint-disable-next-line
            state.uiState = { ...initialState.uiState };
        },
    },
});

export default newSurveyDesignSlice.reducer;

export const { setFormState, setCurrentStep, reset } = newSurveyDesignSlice.actions;
