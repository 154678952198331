import React from 'react';
import Hexagon from 'src/shared/components/charts/hexagon/Hexagon';
import { FishMetricsQueryResult } from 'src/app/dashboard/state/api/queries/fishMetricsQuery';
import FishIndexBackground from 'src/assets/images/dashboard/fishmetrics-cover.webp';
import QuestionButtonHelp from 'src/shared/components/question-button-help/QuestionButtonHelp';
import { legendSlug } from '../fish-metrics-chart/FishMetricsChart';
import fishMetricsImageUrl from 'src/assets/images/dashboard/fishmetrics.svg?url';
import styles from './FishMetricsSingleChart.module.scss';

const hexagonColours: { [key: string]: [string, string] } = {
    red: ['#F9DFE0', '#AD180E'],
    amber: ['#FED693', '#E3A52D'],
    green: ['#5A824D', '#CAD7C6'],
};

const HEXAGON_SIZE = 180;

const FishMetricsSingleChart = ({ data }: { data: FishMetricsQueryResult }) => {
    const commercialValue: number = data.fishcommercialvalue?.data[0]?.data[0].group.find(item => item.type === 'all')?.value ?? 0;
    const foodChainData: number = data.fishfoodchainintegrity?.data[0]?.data[0].group.find(item => item.type === 'all')?.value ?? 0;
    const prevalanceData: number =
        data.fishprevalenceofsensitivespecies?.data[0]?.data[0].group.find(item => item.type === 'all')?.value ?? 0;

    const metricsData = [
        {
            title: 'Commercial value',
            score: commercialValue,
            colours: getHexagonColour(commercialValue),
        },
        {
            title: 'Food chain integrity',
            score: foodChainData,
            colours: getHexagonColour(foodChainData),
        },
        {
            title: 'Prevalence of sensitive species',
            score: prevalanceData,
            colours: getHexagonColour(prevalanceData),
        },
    ];

    const backgroundImageStyle = {
        backgroundImage: `url(${fishMetricsImageUrl})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: '150% center',
        backgroundSize: '40%',
    };

    return (
        <div className={styles.container}>
            <div className={styles.imageContainer}>
                <img src={FishIndexBackground} alt='' />
            </div>
            <div className={styles.contentBucket}>
                <div className={styles.titleContainer}>
                    <h3 className={styles.title}>Fish Metrics</h3>
                    <p className={styles.subTitle}>A description of the overall health of fish communities within an ecosystem.</p>
                </div>
                <div className={styles.metricContainer} style={backgroundImageStyle}>
                    {metricsData.map((metric, index) => (
                        <div className={styles.metric} key={index}>
                            <div className={styles.header}>
                                <span className={styles.title}>{metric.title}</span>
                                <span className={styles.helpButton}>
                                    <QuestionButtonHelp type='api' slug={legendSlug[metric.title]} />
                                </span>
                            </div>
                            <div>
                                <Hexagon
                                    thickness={20}
                                    score={metric.score}
                                    size={HEXAGON_SIZE}
                                    content={
                                        <div className={styles.hexagonContent}>
                                            <div className={styles.scores}>
                                                <span className={styles.score}>{metric.score}</span>
                                                <span className={styles.maxScore}>/{100}</span>
                                            </div>
                                            <span
                                                className={styles.result}
                                                style={{
                                                    color: metric.colours[1],
                                                    backgroundColor: metric.colours[0],
                                                }}
                                            >
                                                {getLevelBarValue({ score: metric.score })}
                                            </span>
                                        </div>
                                    }
                                    fixedColours={metric.colours}
                                />
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

const getHexagonColour = (value: number) => {
    if (value < 41) {
        return hexagonColours.red;
    } else if (value < 61) {
        return hexagonColours.amber;
    }

    return hexagonColours.green;
};

type GetLevelBarValueProps = {
    score: number;
};

const getLevelBarValue = (props: GetLevelBarValueProps) => {
    const { score } = props;

    if (score < 21) {
        return 'Very low';
    } else if (score < 41) {
        return 'Low';
    } else if (score < 61) {
        return 'Medium';
    } else if (score < 81) {
        return 'High';
    }

    return 'Very high';
};

export default FishMetricsSingleChart;
