import React, { useEffect, useState } from 'react';
import { useAppSelector, useAppDispatch } from 'src/store';
import { getNewProjectUserFormState } from 'src/app/admin/views/user-management/state/userManagementSelector';
import { NewProjectUserFormState, setFormState } from 'src/app/admin/views/user-management/state/userManagementSlice';
import ErrorMessage from 'src/shared/components/error-msg/ErrorMessage';
import Search from '@mui/icons-material/Search';
import Autocomplete from 'src/shared/components/autocomplete/Autocomplete';
import classNames from 'classnames';
import { User } from 'src/shared/types/graph/user';
import UserDetails from 'src/app/admin/views/user-management/components/user-details/UserDetails';
import StatusIndicator from 'src/shared/components/status-indicator/StatusIndicator';
import { getUserStatusFromEnum } from 'src/app/admin/views/user-management/components/user-management-list/UserManagementList';
import { useCustomersQuery } from 'src/app/customers/state/api/customersGraphSlice';
import { UserType } from 'src/shared/types/user';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { isEmailIdValid } from 'src/shared/helpers/emailHelpers';
import { useGetUserQuery } from 'src/app/admin/views/user-management/state/api/userManagementGraphSlice';
import useCheckUserExists from 'src/app/admin/views/user-management/hooks/useCheckUserExists';
import styles from './SelectExistingUser.module.scss';

const SelectExistingUser = () => {
    const formState = useAppSelector(getNewProjectUserFormState);
    const dispatch = useAppDispatch();
    const [userSearchText, setUserSearchText] = useState<string>('');
    const [userOptions, setUserOptions] = useState<string[]>([]);
    const { data: checkUserExistsData, isFetching } = useGetUserQuery({ email: userSearchText }, { skip: !isEmailIdValid(userSearchText) });
    const userAlreadyExists = useCheckUserExists(formState.selectedUser);

    const onFormChange = (fragment: Partial<NewProjectUserFormState['formState']>) => {
        dispatch(
            setFormState({
                ...fragment,
            })
        );
    };

    const onUserSearchTextChange = async (value: string) => {
        setUserSearchText(value);
    };

    useEffect(() => {
        if (isFetching) {
            return;
        }

        if (checkUserExistsData) {
            const {
                admin: {
                    user: { email },
                },
            } = checkUserExistsData;

            setUserOptions([email]);
        }
    }, [isFetching]);

    const labelClassNames = classNames(styles.label, {
        [styles.labelError]: userAlreadyExists,
    });

    const shouldShowInfo = formState.selectedUser && !userAlreadyExists;

    return (
        <div className={styles.selectExistingUser}>
            <div className={styles.fields}>
                {!shouldShowInfo && (
                    <div>
                        <div className={labelClassNames}>Find an existing platform user to add to this organisation</div>
                        <Autocomplete
                            options={userOptions}
                            placeholder='type full email address'
                            prefixIcon={<Search />}
                            onChange={() => {
                                onFormChange({ selectedUser: checkUserExistsData?.admin?.user as User });
                            }}
                            value={formState.selectedUser?.email || ''}
                            inputValue={userSearchText}
                            onInputChange={onUserSearchTextChange}
                        />
                    </div>
                )}
                {shouldShowInfo && <SelectedUserInfo user={formState.selectedUser as User} onFormChange={onFormChange} />}

                {userAlreadyExists && <ErrorMessage message={<>This user already exists within this organisation</>} />}
            </div>
        </div>
    );
};

type SelectedUserInfoProps = {
    user: User;
    onFormChange: (fragment: Partial<NewProjectUserFormState['formState']>) => void;
};

const SelectedUserInfo = (props: SelectedUserInfoProps) => {
    const { user, onFormChange } = props;
    const { currentData } = useCustomersQuery({});
    const customers = currentData?.customers;

    return (
        <div className={styles.userInfo}>
            <div className={styles.close}>
                <IconButton onClick={() => onFormChange({ selectedUser: null })}>
                    <CloseIcon />
                </IconButton>
            </div>
            <UserDetails {...user} userType={UserType.INTERNAL} />
            <div className={styles.sectionSeparator}>&nbsp;</div>
            <div>User’s current organisations:</div>
            <div className={styles.currentOrganisations}>
                {user.customers.map((userCustomer, index) => (
                    <div key={index} className={styles.userOrganisation}>
                        <div className={styles.name}>
                            <div key={userCustomer.customerId}>
                                {customers?.find(customer => customer.customerId === userCustomer.customerId)?.customerName}
                            </div>
                        </div>
                        <div className={styles.status}>
                            <StatusIndicator status={getUserStatusFromEnum(user.status)} statusText={user.status} />
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default SelectExistingUser;
