import React from 'react';
import NoDataIcon from 'src/assets/images/customers-nodata.svg?url';
import styles from './HexagonImages.module.scss';

type ImagesData = {
    imageUrl: string;
    text: string;
    imageSize?: number | string;
};

const HexagonImages = ({ imagesData }: { imagesData: ImagesData[] }) => {
    const imageLabels = imagesData?.map(item => item.text);
    return (
        <div className={styles.hexContainer}>
            <div className={styles.hexGroup}>
                {imagesData?.map((item: ImagesData, index: number) => (
                    <div
                        className={styles.hexagon}
                        key={index}
                        style={{ zIndex: 100 - index, width: item?.imageSize || 50, height: item?.imageSize || 50 * 1.1 }}
                        data-testid='hexagon'
                    >
                        <div className={styles.hexGroupIcon}>
                            <img src={item?.imageUrl !== '' ? item?.imageUrl : NoDataIcon} alt={item.text} />
                        </div>
                    </div>
                ))}
            </div>
            <p className={styles.types}>{imageLabels?.join(', ')}</p>
        </div>
    );
};

export default HexagonImages;
