import React from 'react';
import memoize from 'lodash/memoize';
import sortBy from 'lodash/sortBy';

import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

import { FishMetricsQueryResult } from 'src/app/dashboard/state/api/queries/fishMetricsQuery';
import moment from 'moment';
import QuestionButtonHelp from 'src/shared/components/question-button-help/QuestionButtonHelp';

import styles from './FishMetricsChart.module.scss';

const FishMetricsChart = ({ data }: { data: FishMetricsQueryResult }) => {
    const chartValues: {
        [key: string]: {
            'Commercial value'?: number;
            'Food chain integrity'?: number;
            'Prevalence of sensitive species'?: number;
            date: string;
        };
    } = {};

    const commercialData = data.fishcommercialvalue?.data[0]?.data;
    const foodChainData = data.fishfoodchainintegrity?.data[0]?.data;
    const prevalanceData = data.fishprevalenceofsensitivespecies?.data[0]?.data;

    commercialData?.forEach(entry => {
        const allEntry = entry.group.find(item => item.type === 'all');

        if (allEntry) {
            chartValues[entry.datetime] = {
                'Commercial value': allEntry.value,
                date: moment(Number(entry.datetime)).format('YYYY'),
            };
        }
    });

    foodChainData?.forEach(entry => {
        const allEntry = entry.group.find(item => item.type === 'all');

        if (allEntry) {
            if (chartValues[entry.datetime]) {
                chartValues[entry.datetime]['Food chain integrity'] = allEntry.value;
            } else {
                chartValues[entry.datetime] = {
                    'Food chain integrity': allEntry.value,
                    date: moment(Number(entry.datetime)).format('YYYY'),
                };
            }
        }
    });

    prevalanceData?.forEach(entry => {
        const allEntry = entry.group.find(item => item.type === 'all');

        if (allEntry) {
            if (chartValues[entry.datetime]) {
                chartValues[entry.datetime]['Prevalence of sensitive species'] = allEntry.value;
            } else {
                chartValues[entry.datetime] = {
                    'Prevalence of sensitive species': allEntry.value,
                    date: moment(Number(entry.datetime)).format('YYYY'),
                };
            }
        }
    });

    return (
        <ResponsiveContainer height={400}>
            <LineChart
                width={600}
                height={300}
                data={sortBy(Object.values(chartValues), ['date'], ['asc'])}
                margin={{
                    top: 5,
                    right: 20,
                    left: 50,
                    bottom: 5,
                }}
            >
                <CartesianGrid vertical={false} horizontalPoints={[300, 240, 180, 120, 60]} />
                <XAxis dataKey='date' tickLine={false} />
                <YAxis
                    dataKey={'Prevalence of sensitive species'}
                    domain={[0, 100]}
                    axisLine={false}
                    tickLine={false}
                    ticks={['91', '71', '51', '31', '10']}
                    tickFormatter={tickFormatter}
                    tickMargin={20}
                    width={40}
                />
                <Tooltip />
                <Legend
                    verticalAlign='top'
                    margin={{ bottom: 20 }}
                    iconType='plainline'
                    height={60}
                    iconSize={20}
                    formatter={renderLegend as any}
                />
                <Line type='linear' dataKey='Commercial value' stroke='#0FC0C7' fill='#0FC0C7' strokeWidth={3} dot={false} />
                <Line type='linear' dataKey='Food chain integrity' stroke='#386EB9' fill='#386EB9' strokeWidth={3} dot={false} />
                <Line type='linear' dataKey='Prevalence of sensitive species' stroke='#BC4E7C' fill='#BC4E7C' strokeWidth={3} dot={false} />
            </LineChart>
        </ResponsiveContainer>
    );
};

export const legendSlug: { [key: string]: string } = {
    'Commercial value': 'fish-commercial-value',
    'Food chain integrity': 'food-chain-integrity',
    'Prevalence of sensitive species': 'fish-prevalance-of-sensitive-species',
};
const renderLegend = memoize((value: string, entry: { color: string; dataKey: string }) => {
    return (
        <span className={styles.legend}>
            <span style={{ color: 'black' }}>
                {value}{' '}
                <QuestionButtonHelp type='api' slug={legendSlug[entry.dataKey]} detailedExplainerSlug={`${legendSlug[entry.dataKey]}-te`} />
            </span>
        </span>
    );
});

export const tickFormatter = (value: number) => {
    if (value < 21) {
        return 'Very low';
    } else if (value < 41) {
        return 'Low';
    } else if (value < 61) {
        return 'Medium';
    } else if (value < 81) {
        return 'High';
    }

    return 'Very high';
};

export default FishMetricsChart;
