import { useEffect, useState } from 'react';
import useAuthService from 'src/shared/hooks/useAuthService';
import {
    useGetCustomerUsersQuery,
    useGetCustomerUsersForSuperAdminQuery,
} from 'src/app/admin/views/user-management/state/api/userManagementGraphSlice';
import useCustomer from 'src/app/customers/hooks/useCustomer';
import { User } from 'src/shared/types/graph/user';

const useCheckUserExists = (selectedUser: User | null) => {
    const [userAlreadyExists, setUserAlreadyExists] = useState(false);
    const { currentCustomerId } = useCustomer();
    const { isCurrentUserSuperAdmin, isCurrentUserOnboardingManager } = useAuthService();

    const { currentData } = useGetCustomerUsersQuery({
        customerId: currentCustomerId,
    });

    const { currentData: currentSuperAdminData } = useGetCustomerUsersForSuperAdminQuery(
        {
            customerId: currentCustomerId,
            showOnlyExternalUsers: false,
        },
        { skip: !(isCurrentUserSuperAdmin || isCurrentUserOnboardingManager) }
    );

    const users = [...(currentData?.users ?? []), ...(currentSuperAdminData?.admin.users ?? [])];

    useEffect(() => {
        if (!users) {
            return;
        }
        const user = users.find((user: User) => user.id === Number(selectedUser?.id));
        setUserAlreadyExists(Boolean(user));
    }, [selectedUser, users]);

    return userAlreadyExists;
};

export default useCheckUserExists;
