import { gql } from 'graphql-request';
import { ProjectDefinitionCompletionStatus } from 'src/shared/types/graph/project';
import { SubscriptionCategories } from 'src/shared/types/graph/subscription';

export const projectDefinitionCompletionQuery = gql`
    query checkIfProjectHasDefinition($projectId: String!) {
        hasProjectDefinition(projectId: $projectId) {
            exists
            metadata {
                id
                status
                createdBy
                creatorName
                isCreatedByCurrentUser
            }
        }
    }
`;

export type ProjectDefinitionCompletionQueryResult = {
    hasProjectDefinition: ProjectDefinitionCompletionStatus;
};

export const createProjectDefinitonQuery = gql`
    mutation saveProjectDefinition($input: ProjectDefinitionInput!) {
        saveProjectDefinition(input: $input)
    }
`;

export type CreateProjectDefinitionQueryResult = {
    id: string;
};

export const getSubscriptionTypesQuery = gql`
    query getSubscriptionTypes {
        subscriptionCategories {
            name
            label
            projectTypes
            tags
        }
    }
`;
export type GetSubscriptionTypeResult = {
    subscriptionCategories: SubscriptionCategories;
};
