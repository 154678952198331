import { gql } from 'graphql-request';
import { Marker } from 'src/shared/types/graph/marker';

export const communityComparisonMarkerQuery = gql`
    query markers($projectId: String!, $sampleId: String!) {
        markers(projectId: $projectId, sampleId: $sampleId) {
            assayId
            numberNamedSpecies
            results {
                taxonomy
            }
            metrics
        }
    }
`;

type MarkersData = Pick<Marker, 'assayId' | 'numberNamedSpecies' | 'results' | 'metrics'>[];

export type communityComparisonMarkersQueryResult = {
    markers: MarkersData;
};
