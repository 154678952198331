import { SelectedDateRange } from 'src/shared/components/filters/date-filter/DateFilter';
import { RootState } from 'src/store';
import { ProjectUIFiltersState, ProjectUIState } from './projectListSlice';
import projectsStorageService from 'src/app/projects/services/projects-storage-service';
import { HabitatAssay } from 'src/shared/types/graph/project';

export const getCurrentProjectId = (state: RootState): string | null => {
    return state.projects.projectList?.currentProjectId || projectsStorageService.getCurrentProjectId();
};

export const getCurrentProjectUIState = (state: RootState): ProjectUIState | null => {
    const currentProjectId = state.projects.projectList?.currentProjectId;

    if (!currentProjectId) {
        return null;
    }

    return state.projects.projectList.uiState[currentProjectId] || null;
};

export const getCurrentProjectUIFilterState = (state: RootState): ProjectUIFiltersState | null => {
    const currentProjectUIState = getCurrentProjectUIState(state);

    if (!currentProjectUIState) {
        return null;
    }

    return currentProjectUIState.filters || null;
};

export const getCurrentProjectSelectedDateRange = (state: RootState): SelectedDateRange | null => {
    const currentProjectUIFilterState = getCurrentProjectUIFilterState(state);

    if (!currentProjectUIFilterState) {
        return null;
    }

    return currentProjectUIFilterState.selectedDateRange || null;
};

export const getCurrentProjectSelectedHabitatAssayType = (state: RootState): HabitatAssay | null => {
    const currentProjectUIFilterState = getCurrentProjectUIFilterState(state);

    if (!currentProjectUIFilterState) {
        return null;
    }

    return currentProjectUIFilterState.habitatAssayType || null;
};
