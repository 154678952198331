import React, { useRef, useEffect } from 'react';
import { isArray } from 'lodash';
import L, { LatLngExpression } from 'leaflet';
import { MapContainer, Polygon } from 'react-leaflet';
import ReactLeafletGoogleLayer from 'react-leaflet-google-layer';
import { HabitatInsightsGeoJson } from 'src/shared/types/geojson';
import googleMapStyles from 'src/app/maps/views/samples/components/samples-map/samplesMapFeaturesConfig';
import config from 'src/config';
import { availableAreaColors } from 'src/shared/constants/colors';
import MapType from 'src/shared/components/map-type/MapType';
import 'src/app/maps/views/samples/components/samples-map/leaflet.css';
import styles from './HabitatInsightsSamplesReviewMap.module.scss';

type HabitatInsightsSamplesProps = {
    data: HabitatInsightsGeoJson[] | null;
};

const HabitatInsightsUploadReviewMap = ({ data }: HabitatInsightsSamplesProps) => {
    const mapRef = useRef<L.Map>(null);
    const [polygonData, setPolygonData] = React.useState<LatLngExpression[][]>([]);
    const [mapCenter, setMapCenter] = React.useState<LatLngExpression | null>(null);
    const [mapType, setMapType] = React.useState('roadmap');

    useEffect(() => {
        if (isArray(data)) {
            const convertedPolygons: LatLngExpression[][] = data.map((item: HabitatInsightsGeoJson) => {
                return item.geometry.coordinates[0].map(([lng, lat]) => [lat, lng]);
            });

            setPolygonData([...convertedPolygons]);
            const allPolygonCoordinates = convertedPolygons.flat();
            const bounds = L.latLngBounds(allPolygonCoordinates);
            setMapCenter(bounds.getCenter());
        }
    }, [data]);

    useEffect(() => {
        if (mapRef.current && mapCenter) {
            mapRef.current.setView(mapCenter, 9);
        }
    }, [mapCenter]);

    return (
        <div className={styles.mapContainer}>
            <MapContainer ref={mapRef} zoom={6} scrollWheelZoom={true} style={{ width: '100%', height: '100%' }}>
                <ReactLeafletGoogleLayer
                    key={mapType}
                    apiKey={config.googleMapsJavascriptApiKey}
                    useGoogMapsLoader={true}
                    styles={googleMapStyles}
                    type={mapType as any}
                ></ReactLeafletGoogleLayer>
                {polygonData.map((polygon: LatLngExpression[], index: number) => {
                    const pathOptions = {
                        color: availableAreaColors[index],
                        fillOpacity: 0.3,
                    };
                    return <Polygon key={index} positions={polygon} pathOptions={pathOptions} />;
                })}

                <div className='leaflet-bottom leaflet-left'>
                    <div className={styles.legend}>
                        <div className={styles.title}>Key</div>
                        {data &&
                            data.map((entry, index) => (
                                <span className={styles.keyLabel} key={index}>
                                    <i style={{ background: availableAreaColors[index] }}></i>
                                    {entry.properties.name}
                                </span>
                            ))}
                    </div>
                </div>
                <div
                    className='leaflet-bottom leaflet-right'
                    style={{ pointerEvents: 'visible', right: '15px' }}
                    ref={ref => {
                        if (!ref) return;
                        L.DomEvent.disableClickPropagation(ref).disableScrollPropagation(ref);
                    }}
                >
                    <div className={styles.legend}>
                        <div className={styles.title}>Map Type </div>
                        <MapType handleChange={setMapType} value={mapType} />
                    </div>
                </div>
            </MapContainer>
        </div>
    );
};

export default HabitatInsightsUploadReviewMap;
