import { fontWeight } from 'src/styles/variables';
const { light, normal, mediumBold } = fontWeight;

const richnessChartStyles = {
    root: {
        flexGrow: 1,
        boxShadow: '0px 4px 6px -4px rgba(24, 39, 75, 0.12), 0px 8px 8px -4px rgba(24, 39, 75, 0.08)',
        borderRadius: '10px',
        width: 'calc(50% - 10px)',
    },
    chartTitle: {
        textAlign: 'left',
        '& h3': {
            mb: '0.5rem',
            color: '#076769',
            fontSize: '24px',
            fontWeight: mediumBold,
        },
        '& span': { color: '#212121' },
    },
    chartExplainerText: {
        textAlign: 'left',
        color: '#212121',
        fontSize: '14px',
        fontWeight: light,
        mb: '1rem',
        // minHeight: '90px',
    },

    speciesNumber: {
        fontWeight: normal,
        fontSize: '72px',
        color: '#262D38',
        marginRight: '1rem',
    },
    speciesDetectedText: {
        color: '#666666',
        fontWeight: normal,
        fontSize: '16px',
    },

    status: {
        textAlign: 'left',
        marginTop: '0.5rem',
    },

    qrtrText: { fontSize: '14px', textAlign: 'right', color: '#666666' },

    graphSection: {
        // minHeight: '200px',
        aspectRatio: 16 / 60,
        marginTop: '1.5rem',
    },
    chipBar: {
        textAlign: 'left',
    },
    chip: {
        fontWeight: normal,
        fontSize: '14px',
        alignItems: 'center',
        margin: '0 0.5rem 0 0',
        padding: '0 0.1rem',
        height: '20px',
        '&.red': { backgroundColor: '#F4AFAF', color: '#B42E2E' },
        '&.amber': { backgroundColor: '#F7DFC3', color: '#BD983A' },
        '&.green': { backgroundColor: '#C7F4B7', color: '#5A824D' },
    },

    infoText: {
        fontSize: '14px',
        textAlign: 'right',
        color: '#666666',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        '& svg': {
            marginBottom: '-4px',
            height: '16px',
        },
    },

    description: {
        textAlign: 'left',
        marginTop: '1rem',
        fontWeight: light,
        fontSize: '14px',
        lineHeight: '18px',
    },
};

export default richnessChartStyles;
