import React from 'react';
import { useNavigate } from 'react-router-dom';
import { SecondaryButton } from 'src/shared/components/button/Button';
import { Access } from 'src/shared/types/graph/user';
import usePermissions from 'src/shared/hooks/usePermissions';
import { Resources } from 'src/shared/types/graph/resources';
import useCustomer from 'src/app/customers/hooks/useCustomer';

const ProjectAdminButton = () => {
    const { currentCustomerId } = useCustomer();
    const { hasPermission } = usePermissions();
    const navigate = useNavigate();

    const canAdminProject = hasPermission({
        to: [Access.CREATE, Access.UPDATE],
        customerId: currentCustomerId,
        resource: Resources.PROJECT_DEFINITION,
    });

    const projectAdminBtn = canAdminProject ? (
        <div style={{ marginBottom: '40px' }}>
            <SecondaryButton onClick={() => navigate('/projects/project-admin')}>Go to project admin</SecondaryButton>
        </div>
    ) : null;

    return projectAdminBtn;
};

export default ProjectAdminButton;
