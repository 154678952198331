import { gql } from 'graphql-request';
import { ProjectDefinitionCompletionStatus } from 'src/shared/types/graph/project';

export const surveyDesignCompletionQuery = gql`
    query checkIfProjectHasSurveyDesign($projectId: String!) {
        hasSurveyDesign(projectId: $projectId) {
            exists
            metadata {
                id
                status
                createdBy
                creatorName
                isCreatedByCurrentUser
            }
        }
    }
`;

export type SurveyDesignCompletionQueryResult = {
    hasSurveyDesign: ProjectDefinitionCompletionStatus;
};

export const createSurveyDesignQuery = gql`
    mutation saveSurveyDesign($input: SurveyDesignInput!) {
        saveSurveyDesign(input: $input)
    }
`;

export type CreateSurveyDesignQueryResult = {
    id: string;
};
