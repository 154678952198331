import { gql } from 'graphql-request';
import { Sample } from 'src/shared/types/graph/samples';

export const sampleMetricsTableQuery = gql`
    query getSamples($projectId: String!, $samples: sampleFilter) {
        sampleMetricsTableData: project(projectId: $projectId, samples: $samples) {
            samples {
                sampleId
                sampleReceivedDate
                metrics
                covariates
                location {
                    coordinates
                }
            }
        }
    }
`;

type SampleMetricsTableData = {
    samples: Pick<Sample, 'sampleId' | 'sampleReceivedDate' | 'metrics' | 'covariates' | 'location'>[];
};

export type SampleMetricsTableQueryResult = {
    sampleMetricsTableData: SampleMetricsTableData;
};
