import { gql } from 'graphql-request';
import { SurveyDesign } from 'src/shared/types/graph/survey';

export const surveyDesignsQuery = gql`
    query getSurveyDesignList($customerId: String!, $surveyDesignStatus: ProgressStatus) {
        listSurveyDesigns(customerId: $customerId, progressStatus: $surveyDesignStatus) {
            projectId
            projectName
            projectCode
            projectType
            area
            country
            targetOutcome
            projectOutline
            surveyPublishDate
            surveySamples {
                sampleId
                samplePoint
                sampleGroup
                kitType
                latitude
                longitude
                habitatAssayTypes {
                    habitatName
                    assayName
                    habitatAssayKey
                }
            }
            habitatAssayTypes {
                habitatName
                assayName
                habitatAssayKey
            }
            samplingCadence {
                type
                samplingEventsPerYear
                events {
                    name
                    fromDate
                    toDate
                }
            }
            sampleGroups
            speciesOfInterest
            leadName
            leadEmail
            leadRole
            subscriptionType
            subscription {
                name
                label
            }
            country
            createdAt
            creatorName
            status
            sites {
                type
                uploadedAt
                uploadedBy
                geometry {
                    coordinates
                }
                properties {
                    name
                    area
                    status
                    statusMessage
                    metrics {
                        metric
                        layers {
                            year
                            status
                            url
                        }
                    }
                }
            }
        }
    }
`;

export type SurveyDesignsQueryResult = {
    listSurveyDesigns: SurveyDesign[];
};
