import React, { ReactElement } from 'react';
import uniq from 'lodash/uniq';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import CardContent from '@mui/material/CardContent';
import Card from '@mui/material/Card';
import Chip from '@mui/material/Chip';
import useProject from 'src/shared/hooks/useProject';
import { useSpeciesRichnessMetricsQuery } from 'src/app/dashboard/state/metrics/metricsGraphSlice';
import { LinearLoader } from 'src/shared/components/loader/Loader';
import moment from 'moment';
import RichnessReChart from 'src/shared/components/charts/richness-chart/RichnessReChart';
import QuestionButtonHelp from 'src/shared/components/question-button-help/QuestionButtonHelp';
import style from './SpeciesRichnessMetrics.style';
import CMSArticle from 'src/shared/components/cms-article/CMSArticle';
import NoData from 'src/shared/components/no-data/NoData';
import HexagonRichness from 'src/assets/images/dashboard/hexagon-richness.png';
import MetricCard from 'src/shared/components/metric-card/MetricCard';

const SpeciesRichnessMetrics = ({ isSingleTimepoint }: { isSingleTimepoint?: boolean }) => {
    const chartTitle = 'Species Richness';
    const { currentProjectSelectedDateRange, currentProjectId, currentProjectSelectedHabitatAssayType: habitatAssayType } = useProject();

    const { currentData, isFetching } = useSpeciesRichnessMetricsQuery(
        {
            projectId: currentProjectId || '',
            fromDate: currentProjectSelectedDateRange?.startDate,
            toDate: currentProjectSelectedDateRange?.endDate,
            habitatAssay: habitatAssayType?.key || '',
        },
        {
            skip: !currentProjectId || !habitatAssayType,
        }
    );

    if (isFetching) {
        return (
            <>
                <LinearLoader />
            </>
        );
    }

    if (!currentData) {
        return null;
    }

    const speciesRichnessData = currentData?.speciesRichness?.data;
    const richnessData = speciesRichnessData?.[0]?.data;

    if (!speciesRichnessData || !speciesRichnessData[0] || !richnessData?.length) {
        return isSingleTimepoint ? (
            <MetricCard
                header={chartTitle}
                helpText={
                    <QuestionButtonHelp
                        type='api'
                        placement='right'
                        slug='evolutionary-diversity'
                        detailedExplainerSlug='evolutionary-diversity-te'
                    />
                }
                noData={true}
            />
        ) : (
            <SpeciesRichnessWrapper chartTitle={chartTitle}>
                <NoData
                    image={HexagonRichness}
                    text='There is insufficient data to display this chart.'
                    subText='Speak to our Customer Success team on how to update your sampling plan'
                    height='40vh'
                />
            </SpeciesRichnessWrapper>
        );
    }

    const chartData = richnessData.flatMap(entry =>
        entry.group
            .filter(item => item.type === 'all')
            .map(point => ({
                value: point.value,
                date: Number(entry.datetime),
            }))
    );

    if (isSingleTimepoint) {
        return (
            <MetricCard
                header={chartTitle}
                content='average species per sample'
                helpText={
                    <QuestionButtonHelp type='api' placement='right' slug='species-richness' detailedExplainerSlug='species-richness-te' />
                }
                total={chartData[0].value}
            />
        );
    }

    const timePoints = uniq(richnessData.flatMap(entry => moment(Number(entry.datetime))));

    const firstYearMeanEndDatetime = moment(timePoints[0]).add(1, 'year').valueOf();
    const firstYearValues = richnessData
        .filter(entry => Number(entry.datetime) < firstYearMeanEndDatetime)
        .flatMap(entry => entry.group.filter(item => item.type === 'all').map(point => point.value));

    let firstYearAverage =
        firstYearValues.reduce((previousValue, currentValue) => previousValue + currentValue, 0) / firstYearValues.length;
    firstYearAverage = Number(firstYearAverage.toFixed(2));
    const endYearWithQuarter = timePoints.at(-1)?.format('Q YYYY');

    const latestQuarterDetectedSpeciesCount = richnessData.at(-1)?.group.find(item => item.type === 'all')?.value || 0;

    let meanPercentageDifference = Math.round(((latestQuarterDetectedSpeciesCount - firstYearAverage) / firstYearAverage) * 100);
    if (isNaN(meanPercentageDifference)) {
        meanPercentageDifference = 0;
    }
    const percentageDifferenceColor = meanPercentageDifference < -5 ? 'red' : meanPercentageDifference > 5 ? 'green' : 'amber';
    const signedMeanPercentageDifference = meanPercentageDifference > 0 ? `+${meanPercentageDifference}%` : `${meanPercentageDifference}%`;

    // Calculate the percentage difference between first and last sampling event values
    const firstSamplingEventValue = richnessData[0].group[0].value;
    let latestSamplePercentageDifference = Math.round(
        ((latestQuarterDetectedSpeciesCount - firstSamplingEventValue) / firstSamplingEventValue) * 100
    );

    if (isNaN(latestSamplePercentageDifference)) {
        latestSamplePercentageDifference = 0;
    }

    return (
        <SpeciesRichnessWrapper chartTitle={chartTitle}>
            <>
                <Box>
                    <Typography variant='h2' component='span' sx={style.speciesNumber}>
                        {latestQuarterDetectedSpeciesCount === 0 ? '0' : latestQuarterDetectedSpeciesCount.toFixed(2)}
                    </Typography>
                    <Typography variant='body2' component='span' sx={style.speciesDetectedText}>
                        Average per sample Q{endYearWithQuarter}
                    </Typography>
                </Box>
                {meanPercentageDifference && (
                    <Grid container direction='row' justifyContent='space-between' alignItems='stretch' sx={style.status}>
                        <Grid item>
                            <Chip
                                sx={style.chip}
                                className={percentageDifferenceColor}
                                label={signedMeanPercentageDifference}
                                component='span'
                            />
                            <Typography variant='body2' component='span' sx={style.qrtrText}>
                                vs Mean of first 12 months of samples
                            </Typography>
                        </Grid>
                        <Grid sx={style.infoText}>
                            <Typography>--- first year mean</Typography>
                            <QuestionButtonHelp type='api' placement='left' slug='first-year-mean-explainer' />
                        </Grid>
                    </Grid>
                )}

                <RichnessReChart chartData={chartData} baseLineValue={firstYearAverage} />

                <Box sx={style.description}>
                    <Typography variant='body2' component='span'>
                        <CMSArticle
                            slug='species-richness-chart-footer-description'
                            substitutions={{
                                percentageDifference: String(latestSamplePercentageDifference),
                                increasedOrDecreased: latestSamplePercentageDifference > 0 ? 'increased' : 'decreased',
                            }}
                        />
                    </Typography>
                </Box>
            </>
        </SpeciesRichnessWrapper>
    );
};

const SpeciesRichnessWrapper = (props: { children: ReactElement; chartTitle?: string }) => {
    return (
        <Card sx={style.root}>
            <CardContent>
                <Box sx={style.chartTitle}>
                    <Typography variant='h3' component='h3' data-testid='chart-title'>
                        {props.chartTitle}
                        <QuestionButtonHelp
                            type='api'
                            placement='right'
                            slug='species-richness'
                            detailedExplainerSlug='species-richness-te'
                        />
                    </Typography>
                </Box>
                {props.children}
            </CardContent>
        </Card>
    );
};

export default SpeciesRichnessMetrics;
