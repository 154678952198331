import Ajv from 'ajv';
import { HabitatInsightsGeoJsonValidationError } from './state/habitatInsightsUploadSlice';
import { isValidLatitude, isValidLongitude } from 'src/shared/helpers/coordinateHelpers';
import { habitatInsightsSamplesAjvValidationSchema, HabitatInsightsGeoJson, HabitatInsightsGeoJsonFile } from 'src/shared/types/geojson';
import { uniq } from 'lodash';

export const validateHabitatInsightsGeoJSON = (jsonArrayString: string): HabitatInsightsGeoJsonValidationError[] | [] => {
    try {
        const featureCollection: HabitatInsightsGeoJsonFile = JSON.parse(jsonArrayString);
        const errors: HabitatInsightsGeoJsonValidationError[] = [];

        try {
            const ajv = new Ajv();
            const validate = ajv.compile(habitatInsightsSamplesAjvValidationSchema);
            const isValid = validate(featureCollection);
            if (isValid) {
                if (uniq(featureCollection.features.map(entry => entry.properties.name)).length > 8) {
                    errors.push({
                        type: 'InvalidInputLength',
                        message: '<p>More than 8 sites identified.</p> <p>Please ensure that 8 or fewer sites are listed in the file.</p>',
                    });
                }
                featureCollection.features.forEach((geoJsonObject: HabitatInsightsGeoJson) => {
                    const coordinates = geoJsonObject.geometry.coordinates;
                    if (
                        !coordinates[0].every(
                            ([longitude, latitude]: [number, number]) => isValidLatitude(latitude) && isValidLongitude(longitude)
                        )
                    ) {
                        errors.push({
                            type: 'InvalidCoordinatesError',
                            message:
                                '<p>File contains invalid latitude(s) or longitude(s).</p><p> Please ensure latitude values range between -90 to 90 and longitude values range between -180 to 180.</p>',
                        });
                    }
                });
            } else {
                errors.push({
                    type: 'InvalidGeometryTypeError',
                    message:
                        '<p>File is not polygon geometry type.</p><p> Please ensure that the file only contains polygon geometry type.</p>',
                });
            }
        } catch (error) {
            return [{ type: 'InvalidJSONFormatError', message: 'Invalid JSON format.' }];
        }
        return errors.length > 0 ? errors : [];
    } catch (error) {
        return [{ type: 'InvalidJSONFormatError', message: 'Invalid JSON format.' }];
    }
};
