import { useEffect, useState } from 'react';
import capitalize from 'lodash/capitalize';
import { useGetSubscriptionTypesQuery } from 'src/app/projects/views/new-project-definition/state/api/newProjectDefinitionGraphSlice';
import { SelectOption } from 'src/shared/components/select/Select';
import { SubscriptionTag } from 'src/shared/types/graph/subscription';

const useSubscriptionTypes = (subscriptionType: string | null) => {
    const { currentData: subscriptionTypesData } = useGetSubscriptionTypesQuery(null);
    const [projectSubscriptionTypeOptions, setProjectSubscriptionTypeOptions] = useState<SelectOption[]>([]);
    const [surveySubscriptionTypeOptions, setSurveySubscriptionTypeOptions] = useState<SelectOption[]>([]);
    const [projectTypeOptions, setProjectTypeOptions] = useState<SelectOption[]>([]);

    useEffect(() => {
        if (!subscriptionTypesData) {
            return;
        }

        const { subscriptionCategories } = subscriptionTypesData;

        setProjectSubscriptionTypeOptions(
            subscriptionCategories
                .filter(entry => entry.tags.includes(SubscriptionTag.PROJECT))
                .map(entry => ({
                    label: entry.label,
                    value: entry.name,
                }))
        );

        setSurveySubscriptionTypeOptions(
            subscriptionCategories
                .filter(entry => entry.tags.includes(SubscriptionTag.SURVEY))
                .map(entry => ({
                    label: entry.label,
                    value: entry.name,
                }))
        );

        if (subscriptionType) {
            const selectedCategory = subscriptionTypesData.subscriptionCategories.find(entry => entry.name === subscriptionType);
            const projectTypes =
                selectedCategory?.projectTypes.map(entry => ({
                    label: capitalize(entry).replace(/_/g, ' '),
                    value: entry,
                })) || [];

            setProjectTypeOptions(projectTypes);
        }
    }, [subscriptionTypesData?.subscriptionCategories, subscriptionType]);

    return { projectSubscriptionTypeOptions, surveySubscriptionTypeOptions, projectTypeOptions };
};

export default useSubscriptionTypes;
