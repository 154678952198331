import { gql } from 'graphql-request';
import { TaxonomySummary } from 'src/shared/types/graph/project';
import { Sample } from 'src/shared/types/graph/samples';
import { Marker } from 'src/shared/types/graph/marker';
import { ChartIds } from 'src/shared/types/graph/chartData';

export const communityComparisonChartDataQuery = gql`
    query getCommunityComparisonChartData($projectId: String!, $fromDate: String, $toDate: String, $habitatAssay: String!) {
        communityComparison: chart(
            projectId: $projectId
            chartId: "${ChartIds.COMMUNITY_COMPARISON}"
            habitatAssay: $habitatAssay
            fromDate: $fromDate
            toDate: $toDate
        ) {
            data
        }
    }
`;

export type SingleYearCommunityComparisonData = {
    variable: {
        key: string;
        value: string;
    };
    comparisons: {
        comparedTo: string;
        sig_diff: boolean;
        p_value: number;
    }[];
    points: {
        sampleId: string;
        coordinates: number[];
    }[];
    polys: {
        order: number;
        coordinates: number[];
    }[];
};

type CommunityComparisonChartData = {
    data: {
        years: {
            data: SingleYearCommunityComparisonData[];
            samplingYear: string;
        }[];
        metric: null;
    }[];
};

export type CommunityComparisonChartDataQueryResult = {
    communityComparison: CommunityComparisonChartData;
};

export const communityComparisonSamplesQuery = gql`
    query getSamples($projectId: String!, $samples: sampleFilter) {
        communityComparisonSamples: project(projectId: $projectId, samples: $samples) {
            taxonomySummary
            samples {
                sampleId
                sampleReceivedDate
                location {
                    coordinates
                    type
                }
                assays {
                    assayId
                    assayName
                    numberNamedSpecies
                }
                covariates
                metrics
            }
        }
    }
`;

type CommunityComparisonSamples = {
    taxonomySummary: TaxonomySummary;
    samples: Sample[];
};

export type CommunityComparisonSamplesQueryResult = {
    communityComparisonSamples: CommunityComparisonSamples;
};

export const markersQuery = gql`
    query markers($projectId: String!, $sampleId: String!) {
        markers(projectId: $projectId, sampleId: $sampleId) {
            assayId
            numberNamedSpecies
            results {
                taxonomy
            }
            metrics
        }
    }
`;

type MarkersData = Pick<Marker, 'assayId' | 'numberNamedSpecies' | 'results' | 'metrics'>[];

export type MarkersQueryResult = {
    markers: MarkersData;
};
