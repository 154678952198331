import React, { useEffect } from 'react';
import { isEqual } from 'lodash';
import { PrimaryButton, SecondaryButton } from 'src/shared/components/button/Button';
import HabitatInsightsSamplesReviewMap from './components/habitat-insights-samples-review-map/HabitatInsightsSamplesReviewMap';
import HabitatInsightsSampleSidebar from './components/habitat-insights-samples-sidebar/HabitatInsightsSamplesSidebar';
import ChevronLeft from '@mui/icons-material/KeyboardArrowLeft';
import { HabitatInsightsGeoJsonFile } from 'src/shared/types/geojson';
import { useAppDispatch, useAppSelector } from 'src/store';
import { getCurrentHabitatInsightsUploadFormState } from '../../state/habitatInsightsUploadSelector';
import { setCurrentStep } from '../../state/habitatInsightsUploadSlice';
import useHabitatInsightsUpload from '../../hooks/useHabitatInsightsUpload';
import styles from './ReviewAndSubmit.module.scss';

const ReviewAndSubmit = () => {
    const formState = useAppSelector(getCurrentHabitatInsightsUploadFormState);
    const dispatch = useAppDispatch();
    const { onFormChange, submitHabitatInsightsUploadForm } = useHabitatInsightsUpload();

    const habitatInsightsSamplesData: HabitatInsightsGeoJsonFile = JSON.parse(formState.uploadedFileContent);

    useEffect(() => {
        if (habitatInsightsSamplesData && habitatInsightsSamplesData.features) {
            const newSites = habitatInsightsSamplesData.features;
            if (!isEqual(newSites, formState.sites)) {
                onFormChange({
                    sites: newSites,
                });
            }
        }
    }, [habitatInsightsSamplesData, formState.sites, onFormChange]);

    const onStepChange = (step: number) => {
        dispatch(setCurrentStep(step));
    };

    const onSubmit = async () => {
        try {
            await submitHabitatInsightsUploadForm();
        } catch (e) {
            console.error(e);
        }
    };
    return (
        <div className={styles.reviewAndSubmit}>
            <div className={styles.container}>
                <div className={styles.mainContent}>
                    <HabitatInsightsSamplesReviewMap data={habitatInsightsSamplesData.features} />
                </div>
                <div className={styles.sidebar}>
                    <HabitatInsightsSampleSidebar data={habitatInsightsSamplesData.features} />
                </div>
            </div>
            <div className={styles.bottomNavigation}>
                <SecondaryButton onClick={() => onStepChange(0)}>
                    <ChevronLeft /> Back
                </SecondaryButton>

                <PrimaryButton onClick={onSubmit}>Submit</PrimaryButton>
            </div>
        </div>
    );
};

export default ReviewAndSubmit;
