import React, { useEffect, useState } from 'react';

import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import CustomerAdminIcon from '@mui/icons-material/WorkspacePremium';
// TODO: Uncomment when Delete button is implemented
// import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';

import { useAppDispatch, useAppSelector } from 'src/store';
import {
    useGetCustomerUsersForSuperAdminQuery,
    useGetCustomerUsersQuery,
} from 'src/app/admin/views/user-management/state/api/userManagementGraphSlice';
import { getUserManagementListFilterState } from 'src/app/admin/views/user-management/state/userManagementSelector';
import { useProjectsQuery } from 'src/app/projects/views/project-list/state/api/projectListGraphSlice';
import useCustomer from 'src/app/customers/hooks/useCustomer';
import UserDetails from '../user-details/UserDetails';
import { TertiaryButton } from 'src/shared/components/button/Button';
import { Access, AssociatedCustomer, Role, UserProjectAccess } from 'src/shared/types/graph/user';
import { Status } from 'src/shared/types/status';
import { UserType } from 'src/shared/types/user';
import { DataTableColumns, IndividualColumn } from 'src/shared/components/data-table/dataTableTypes';
import { DataTableState, TableRowData } from 'src/shared/components/data-table/dataTableTypes';

import DataTable from 'src/shared/components/data-table/DataTable';
import Loader from 'src/shared/components/loader/Loader';
import StatusIndicator from 'src/shared/components/status-indicator/StatusIndicator';
import UserTypeTag from 'src/app/admin/views/user-management/components/user-type-tag/UserTypeTag';
import StatusChip from 'src/shared/components/status-chip/StatusChip';
import useAuthService from 'src/shared/hooks/useAuthService';

import styles from './UserManagementList.module.scss';
import { setEditUserFormMode, setEditUserFormState, updateGeneralUiState } from '../../state/userManagementSlice';
import { batch } from 'react-redux';

enum ColumnType {
    USER_ID = 'userId',
    CUSTOMER_ADMIN_LOGO = 'customerAdminLogo',
    CUSTOMER_NAME = 'customerName',
    ACCOUNT_STATUS = 'accountStatus',
    ACCESS_TO_THIS_ORGANISATION = 'accessToThisOrganisation',
    ROLES = 'roles',
    USER_TYPE = 'userType',
    ASSIGNED_PROJECTS = 'assignedProjects',
    ACTIONS = 'actions',
}

const columns: { [key: string]: IndividualColumn } = {
    [ColumnType.USER_ID]: {
        columnId: 'userId',
        title: 'User Id',
        width: '0',
    },
    [ColumnType.CUSTOMER_ADMIN_LOGO]: {
        columnId: 'customerAdminLogo',
        title: 'Cust. admin',
        width: '70px',
    },
    [ColumnType.CUSTOMER_NAME]: {
        columnId: 'customerName',
        title: 'Name',
        width: '400px',
    },
    [ColumnType.ACCOUNT_STATUS]: {
        columnId: 'accountStatus',
        title: 'Account Status',
        width: '200px',
    },
    [ColumnType.ACCESS_TO_THIS_ORGANISATION]: {
        columnId: 'accessToThisOrganisation',
        title: 'Access to this organisation',
        width: '200px',
    },
    [ColumnType.USER_TYPE]: {
        columnId: 'userType',
        title: 'User type',
        width: '108px',
    },
    [ColumnType.ROLES]: {
        columnId: 'roles',
        title: 'Roles',
        width: '86px',
    },
    [ColumnType.ASSIGNED_PROJECTS]: {
        columnId: 'assignedProjects',
        title: 'Assigned Projects',
        width: '90%',
    },
    [ColumnType.ACTIONS]: {
        columnId: 'actions',
        title: 'Actions',
    },
};

const externalAdminColumns: DataTableColumns = [
    columns[ColumnType.USER_ID],
    columns[ColumnType.CUSTOMER_NAME],
    columns[ColumnType.ACCOUNT_STATUS],
    columns[ColumnType.ACCESS_TO_THIS_ORGANISATION],
    columns[ColumnType.ROLES],
    columns[ColumnType.ASSIGNED_PROJECTS],
    columns[ColumnType.ACTIONS],
];

const superAdminColumns: DataTableColumns = [
    columns[ColumnType.USER_ID],
    columns[ColumnType.CUSTOMER_ADMIN_LOGO],
    columns[ColumnType.CUSTOMER_NAME],
    columns[ColumnType.ACCOUNT_STATUS],
    columns[ColumnType.USER_TYPE],
    columns[ColumnType.ROLES],
    columns[ColumnType.ASSIGNED_PROJECTS],
    columns[ColumnType.ACTIONS],
];

export const getUserStatusFromEnum = (status: string) => {
    switch (status) {
        case 'Active':
            return Status.SUCCESS;
        case 'Invited':
            return Status.WARNING;
        default:
            return Status.ERROR;
    }
};

const checkIfCustomerAdmin = (customerList: AssociatedCustomer[], currentCustomerId: string) => {
    let isAdmin = false;
    customerList.some((customer: AssociatedCustomer) => {
        if (customer.roles?.includes(Role.CUSTOMER_ADMIN) && customer.customerId === currentCustomerId) isAdmin = true;
    });
    return isAdmin;
};

const getUserRoleCount = (currentCustomerProjectIds: string[], userProjects: UserProjectAccess[]) => {
    const userRoles = new Set<Role>();
    userProjects.forEach(project => {
        if (currentCustomerProjectIds.includes(project.projectId)) {
            project.roles?.forEach(role => {
                userRoles.add(role);
            });
        }
    });
    return userRoles.size;
};

const UserManagementList = () => {
    const { currentCustomerId } = useCustomer();
    const { isCurrentUserOnboardingManager, isCurrentUserSuperAdmin } = useAuthService();
    const dispatch = useAppDispatch();

    const { isLoading: isFetchingUsersForAdmin, currentData: currentDataForAdmin } = useGetCustomerUsersQuery(
        {
            customerId: currentCustomerId,
        },
        {
            skip: isCurrentUserOnboardingManager || isCurrentUserSuperAdmin,
        }
    );

    const { isLoading: isFetchingUsersForSuperAdmin, currentData: currentDataForSuperAdmin } = useGetCustomerUsersForSuperAdminQuery(
        {
            customerId: currentCustomerId,
            showOnlyExternalUsers: isCurrentUserOnboardingManager,
        },
        {
            skip: !(isCurrentUserOnboardingManager || isCurrentUserSuperAdmin),
        }
    );

    const { currentData: currentProjectsData } = useProjectsQuery(
        {
            customerId: currentCustomerId,
        },
        {
            skip: !currentCustomerId,
        }
    );

    const userManagementListFilterState = useAppSelector(getUserManagementListFilterState);

    const [tableState, setTableState] = useState<Partial<DataTableState>>({
        fixedHeader: true,
        hiddenColumnIds: new Set(['userId']),
        rowsPerPage: 15,
    });

    const users = [...(currentDataForSuperAdmin?.admin.users || []), ...(currentDataForAdmin?.users || [])].filter(Boolean);

    const onRowSelect = (rowData: TableRowData) => {
        const userId = rowData[0] as string;
        if (userId) {
            const editUserDetails = users.find(user => user.cognitoId === userId);
            if (!editUserDetails) {
                return;
            }
            const userRoles = editUserDetails.customers.find(customer => customer.customerId === currentCustomerId)?.roles;
            dispatch(
                setEditUserFormState({
                    ...editUserDetails,
                    projects: editUserDetails.projects || [],
                    roles: userRoles as Role[],
                })
            );

            dispatch(updateGeneralUiState({ shouldShowViewUserModal: true }));
        }
    };

    useEffect(() => {
        if ((!currentDataForAdmin && !currentDataForSuperAdmin) || !currentProjectsData) {
            return;
        }

        const {
            customer: { projects: projectsForCurrentCustomer },
        } = currentProjectsData;

        const getProjectNameForProjectId = (projectId: string) => {
            return projectsForCurrentCustomer.find(project => project.projectId === projectId)?.projectName.trim();
        };

        let users =
            isCurrentUserSuperAdmin || isCurrentUserOnboardingManager ? currentDataForSuperAdmin?.admin.users : currentDataForAdmin?.users;
        if (!users) users = [];
        const projectIdsForCurrentCustomer = projectsForCurrentCustomer.map(project => project.projectId) || [];
        const tableData: DataTableState['data'] = [];

        users
            .filter(user => {
                const { userNameSearchText, projectNameSearchText, userStatus, userType } = userManagementListFilterState;

                if (userNameSearchText && !(user.firstName + user.lastName).toLowerCase().includes(userNameSearchText.toLowerCase())) {
                    return false;
                }

                const userProjectsCsv =
                    user.projects
                        ?.map(project => getProjectNameForProjectId(project.projectId))
                        .filter(Boolean)
                        .join(', ')
                        .toLowerCase() || '';

                if (projectNameSearchText && !userProjectsCsv.includes(projectNameSearchText.toLowerCase())) {
                    return false;
                }
                if (userStatus !== 'All') {
                    const userTypeString = user.is_internal_user ? UserType.INTERNAL : UserType.CUSTOMER;
                    if (userStatus !== user.status || userType !== userTypeString) {
                        return false;
                    }
                }

                return true;
            })
            .sort((val1, val2) => {
                const isVal1CustomerAdmin = checkIfCustomerAdmin(val1.customers, currentCustomerId);
                const isVal2CustomerAdmin = checkIfCustomerAdmin(val2.customers, currentCustomerId);
                if (isVal1CustomerAdmin && !isVal2CustomerAdmin) {
                    return -1;
                } else if (!isVal1CustomerAdmin && isVal2CustomerAdmin) {
                    return 1;
                } else {
                    return val1.id - val2.id;
                }
            })
            .forEach(user => {
                const { projects } = user;

                const basicDetail = (
                    <UserDetails firstName={user.firstName} lastName={user.lastName} email={user.email} status={user.status} />
                );

                const statusColumn = (
                    <StatusChip
                        label={user.status === 'Inactive' ? 'Deactivated' : user.status}
                        status={getUserStatusFromEnum(user.status)}
                    />
                );

                const totalRoles = getUserRoleCount(projectIdsForCurrentCustomer, user.projects || []);
                const roleColumn = <div className={`${styles.totalRoles} ${styles['totalRoles' + user.status]}`}>{totalRoles}</div>;

                const assignedProjects = (
                    <div className={styles.projectTags}>
                        {projects
                            ?.filter(project => projectIdsForCurrentCustomer.includes(project.projectId))
                            .map((project, index) => {
                                const projectName = getProjectNameForProjectId(project.projectId);
                                return (
                                    <span key={index} className={`${styles.projectTag} ${styles['projectTag' + user.status]}`}>
                                        {projectName}
                                    </span>
                                );
                            })}
                    </div>
                );

                const editActions = (
                    <TertiaryButton
                        className={styles.actions}
                        onClick={e => {
                            e.stopPropagation();
                            batch(() => {
                                dispatch(updateGeneralUiState({ shouldShowEditUserModal: true }));
                                dispatch(setEditUserFormState({ ...user, projects: user.projects || [] }));
                                dispatch(setEditUserFormMode(Access.UPDATE));
                            });
                        }}
                    >
                        <CreateOutlinedIcon /> <span>EDIT</span>
                    </TertiaryButton>
                );

                const isAssignedToCustomer = user.customers.some(customer => customer.customerId === currentCustomerId && customer.active);
                const isAssignedToCustomerColumn = (
                    <StatusIndicator
                        status={isAssignedToCustomer ? Status.SUCCESS : Status.ERROR}
                        statusText={isAssignedToCustomer ? 'Assigned' : 'Unassigned'}
                    />
                );

                let tableRowData = [
                    user.cognitoId,
                    basicDetail,
                    statusColumn,
                    isAssignedToCustomerColumn,
                    roleColumn,
                    assignedProjects,
                    editActions,
                ];

                if (isCurrentUserSuperAdmin || isCurrentUserOnboardingManager) {
                    const isCustomerAdminUser = checkIfCustomerAdmin(user.customers, currentCustomerId);
                    const customerAdminLogo = isCustomerAdminUser ? <CustomerAdminIcon className={styles.customerAdminLogo} /> : '';

                    const userType = user.is_internal_user ? UserType.INTERNAL : UserType.CUSTOMER;
                    const userTypeTag = <UserTypeTag userType={userType} />;

                    tableRowData = [
                        user.cognitoId,
                        customerAdminLogo,
                        basicDetail,
                        statusColumn,
                        userTypeTag,
                        roleColumn,
                        assignedProjects,
                    ];

                    const actions = (
                        <div className={styles.actionButtons}>
                            {editActions}

                            {/* 
                            TODO: Commenting out Delete button until the feature is implemented
                            {isCurrentUserSuperAdmin && (
                                <TertiaryButton
                                    className={isCustomerAdminUser ? styles.actionsDisabled : styles.actions}
                                    disabled={isCustomerAdminUser}
                                    onClick={e => {
                                        e.stopPropagation();
                                    }}
                                >
                                    <DeleteOutlineOutlinedIcon /> <span>Delete</span>
                                </TertiaryButton>
                            )} */}
                        </div>
                    );
                    //In case of Onboarding Manger - Display Edit option only for the Customer Admin User/Row
                    const shouldShowEditButton = isCurrentUserOnboardingManager ? isCustomerAdminUser : true;
                    tableRowData.push(shouldShowEditButton ? actions : '');
                }

                tableData.push(tableRowData);
            });

        setTableState({
            ...tableState,
            data: tableData,
        });
    }, [currentDataForAdmin, currentDataForSuperAdmin, currentProjectsData, userManagementListFilterState]);

    if (isFetchingUsersForAdmin || isFetchingUsersForSuperAdmin) {
        return <Loader />;
    }

    if (!tableState.data) {
        return <Loader />;
    }

    const emptyStateMessage = (
        <div className={styles.noRecordsMessage}>
            There were no user accounts found matching this selection. Adjust the filters to find users in your organisation.
        </div>
    );

    const tableColumns = isCurrentUserOnboardingManager || isCurrentUserSuperAdmin ? superAdminColumns : externalAdminColumns;

    return (
        <div className={styles.userManagementList}>
            <DataTable
                state={tableState}
                columns={tableColumns}
                onStateChange={setTableState}
                emptyStateMessage={emptyStateMessage}
                onRowSelect={onRowSelect}
            />
        </div>
    );
};

export default UserManagementList;
