import React from 'react';
import { TimeSeriesChartData } from 'src/shared/types/graph/chartData';
import { LegendStyleType } from 'src/shared/types/charts';
import CircleIcon from '@mui/icons-material/Circle';
import styles from './CustomLegend.module.scss';
import LegendDashedIcon from './components/LegendDashedIcon';
import LegendAreaIcon from './components/LegendAreaIcon';

export type LegendDataProps = {
    label: string;
    type: string;
    color: string;
    styleType?: LegendStyleType;
    marginTop?: string;
};

export interface CustomLegendProps {
    title: string;
    onClickHandler: (e: { value: string }) => void;
    hoverProps: { [key: string]: boolean | string | null };
    legendData: TimeSeriesChartData[] | LegendDataProps[];
    onMouseOverHandler: (e: { value: string }) => void;
    onMouseOutHandler: () => void;
    resetHanlder: () => void;
}

const CustomLegend = ({
    title,
    onClickHandler,
    hoverProps,
    legendData,
    onMouseOverHandler,
    onMouseOutHandler,
    resetHanlder,
}: CustomLegendProps) => {
    return (
        <div className={styles.legendContainer}>
            <div style={{ fontWeight: 'bold', marginBottom: '5px' }}>{title}</div>
            <ul style={{ listStyle: 'none', padding: 0 }}>
                {legendData.map((entry, index) => (
                    <li
                        key={`item-${index}`}
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            marginTop: entry.styleType && entry.styleType !== LegendStyleType.CIRCLE ? '18px' : '0',
                        }}
                        onClick={() => onClickHandler({ value: entry?.type })}
                        onMouseOver={() => onMouseOverHandler({ value: entry?.type })}
                        onMouseOut={onMouseOutHandler}
                    >
                        {entry.styleType === LegendStyleType.AREA && <LegendAreaIcon color={entry.color || '#979797'} />}
                        {entry.styleType === LegendStyleType.DASHED && <LegendDashedIcon color={entry.color || '#979797'} />}
                        {entry.styleType !== LegendStyleType.DASHED && entry.styleType !== LegendStyleType.AREA && (
                            <CircleIcon
                                style={{
                                    color:
                                        hoverProps.hover === entry.type ||
                                        (!hoverProps.hover && hoverProps[entry.type] === false) ||
                                        hoverProps[entry.type]
                                            ? entry.color
                                            : '#979797',
                                    fontSize: '18px',
                                    opacity: hoverProps[entry.type] ? 0.4 : 1,
                                }}
                            />
                        )}
                        &nbsp;
                        <span
                            data-testid={`legend-item-${entry.type}`}
                            className={hoverProps[entry.type] ? styles.hiddenLegendLabel : styles.legendLabel}
                        >
                            {entry.label.toUpperCase()}
                        </span>
                    </li>
                ))}
            </ul>
            {Object.values(hoverProps).some(Boolean) && !hoverProps.hover && (
                <div className={styles.reset}>
                    <a onClick={resetHanlder}>RESET</a>
                </div>
            )}
        </div>
    );
};

export default CustomLegend;
