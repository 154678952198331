import uniq from 'lodash/uniq';
import moment from 'moment';
import React, { ReactElement } from 'react';
import { useBacterialFunctionalDiversityMetricsQuery } from 'src/app/dashboard/state/metrics/metricsGraphSlice';
import RichnessReChart from 'src/shared/components/charts/richness-chart/RichnessReChart';
import CMSArticle from 'src/shared/components/cms-article/CMSArticle';
import { LinearLoader } from 'src/shared/components/loader/Loader';
import QuestionButtonHelp from 'src/shared/components/question-button-help/QuestionButtonHelp';
import useProject from 'src/shared/hooks/useProject';
import NoData from 'src/shared/components/no-data/NoData';
import MetricCard from 'src/shared/components/metric-card/MetricCard';
import HexagonRichness from 'src/assets/images/dashboard/hexagon-richness.png';
import functionalDiversityImageUrl from 'src/assets/images/dashboard/functional_diversity.svg?url';
import style from 'src/app/dashboard/components/metrics/species-richness-metrics/SpeciesRichnessMetrics.style';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import CardContent from '@mui/material/CardContent';
import Card from '@mui/material/Card';
import Chip from '@mui/material/Chip';

const BacterialFunctioanlDiversityMetrics = ({ isSingleTimepoint }: { isSingleTimepoint?: boolean }) => {
    const chartTitle = 'Bacterial Functional Diversity';

    const { currentProjectSelectedDateRange, currentProjectId, currentProjectSelectedHabitatAssayType: habitatAssayType } = useProject();

    const { currentData, isFetching } = useBacterialFunctionalDiversityMetricsQuery(
        {
            projectId: currentProjectId || '',
            fromDate: currentProjectSelectedDateRange?.startDate,
            toDate: currentProjectSelectedDateRange?.endDate,
            habitatAssay: habitatAssayType?.key || '',
        },
        {
            skip: !currentProjectId || !habitatAssayType,
        }
    );

    if (isFetching) {
        return (
            <>
                <LinearLoader />
            </>
        );
    }

    if (!currentData) {
        return null;
    }

    const bacterialFuncDivMetricsData = currentData.bacterialFunctionalDiversity?.data;

    if (!bacterialFuncDivMetricsData || !bacterialFuncDivMetricsData[0]) {
        return isSingleTimepoint ? (
            <MetricCard
                header={chartTitle}
                helpText={<QuestionButtonHelp type='api' placement='right' slug='bacterial-functional-diversity' />}
                noData={true}
            />
        ) : (
            <BacterialFunctionalDiversityMetricsWrapper chartTitle={chartTitle}>
                <NoData
                    image={HexagonRichness}
                    text='There is insufficient data to display this chart.'
                    subText='Speak to our Customer Success team on how to update your sampling plan'
                    height='40vh'
                />
            </BacterialFunctionalDiversityMetricsWrapper>
        );
    }

    const functionalDiversityMetricsData = bacterialFuncDivMetricsData[0].data;

    const chartData = functionalDiversityMetricsData.flatMap(entry =>
        entry.group
            .filter(item => item.type === 'all')
            .map(point => ({
                value: point.value,
                date: Number(entry.datetime),
            }))
    );

    if (isSingleTimepoint) {
        return (
            <MetricCard
                header={chartTitle}
                content='average functions per sample'
                helpText={<QuestionButtonHelp type='api' placement='right' slug='bacterial-functional-diversity' />}
                total={chartData[0].value}
                backgroundImage={functionalDiversityImageUrl}
            />
        );
    }

    const timePoints = uniq(functionalDiversityMetricsData.flatMap(entry => moment(Number(entry.datetime))));

    const firstYearMeanEndDatetime = moment(timePoints[0]).add(1, 'year').valueOf();
    const firstYearValues = functionalDiversityMetricsData
        .filter(entry => Number(entry.datetime) < firstYearMeanEndDatetime)
        .flatMap(entry => entry.group.filter(item => item.type === 'all').map(point => point.value));

    let firstYearAverage =
        firstYearValues.reduce((previousValue, currentValue) => previousValue + currentValue, 0) / firstYearValues.length;
    firstYearAverage = Number(firstYearAverage.toFixed(2));
    const endYearWithQuarter = timePoints.at(-1)?.format('Q YYYY');

    const latestQuarterMeasurement = functionalDiversityMetricsData.at(-1)?.group.find(item => item.type === 'all')?.value || 0;

    let meanPercentageDifference = Math.round(((latestQuarterMeasurement - firstYearAverage) / firstYearAverage) * 100);
    if (isNaN(meanPercentageDifference)) {
        meanPercentageDifference = 0;
    }
    const percentageDifferenceColor = meanPercentageDifference < -5 ? 'red' : meanPercentageDifference > 5 ? 'green' : 'amber';
    const signedMeanPercentageDifference = meanPercentageDifference > 0 ? `+${meanPercentageDifference}%` : `${meanPercentageDifference}%`;

    // Calculate the percentage difference between first and last sampling event values
    const firstSamplingEventValue = functionalDiversityMetricsData[0].group[0].value;
    let latestSamplePercentageDifference = Math.round(
        ((latestQuarterMeasurement - firstSamplingEventValue) / firstSamplingEventValue) * 100
    );
    if (isNaN(latestSamplePercentageDifference)) {
        latestSamplePercentageDifference = 0;
    }
    return (
        <BacterialFunctionalDiversityMetricsWrapper chartTitle={chartTitle}>
            <>
                <Box>
                    <Typography variant='h2' component='span' sx={style.speciesNumber}>
                        {latestQuarterMeasurement === 0 ? '0' : latestQuarterMeasurement.toFixed(2)}
                    </Typography>
                    <Typography variant='body2' component='span' sx={style.speciesDetectedText}>
                        Diversity measured in Q{endYearWithQuarter}
                    </Typography>
                </Box>

                <Grid container direction='row' justifyContent='space-between' alignItems='stretch' sx={style.status}>
                    <Grid item>
                        <Chip
                            sx={style.chip}
                            className={percentageDifferenceColor}
                            label={signedMeanPercentageDifference}
                            component='span'
                        />
                        <Typography variant='body2' component='span' sx={style.qrtrText}>
                            vs Mean of first 12 months of samples
                        </Typography>
                    </Grid>
                    <Grid sx={style.infoText}>
                        <Typography>--- first year mean</Typography>
                        <QuestionButtonHelp type='api' placement='left' slug='first-year-mean-explainer' />
                    </Grid>
                </Grid>

                <RichnessReChart chartData={chartData} baseLineValue={firstYearAverage} />

                <Box sx={style.description}>
                    <Typography variant='body2' component='span'>
                        <CMSArticle
                            slug='bacterial-functional-diversity-footer-description'
                            substitutions={{
                                percentageDifference: String(latestSamplePercentageDifference),
                                increasedOrDecreased: latestSamplePercentageDifference > 0 ? 'increased' : 'decreased',
                            }}
                        />
                    </Typography>
                </Box>
            </>
        </BacterialFunctionalDiversityMetricsWrapper>
    );
};

const BacterialFunctionalDiversityMetricsWrapper = (props: { children: ReactElement; chartTitle?: string }) => {
    return (
        <Card sx={{ flex: 1 }}>
            <CardContent>
                <Box sx={style.chartTitle}>
                    <Typography variant='h3' component='h3' data-testid='chart-title'>
                        {props.chartTitle}
                    </Typography>
                </Box>
                <Box sx={style.chartExplainerText}>
                    <Typography variant='body2' component='span'>
                        <CMSArticle slug='bacterial-functional-diversity' />
                    </Typography>
                </Box>
                {props.children}
            </CardContent>
        </Card>
    );
};

export default BacterialFunctioanlDiversityMetrics;
