import React from 'react';
import classNames from 'classnames';
import { SpeciesListTableData } from '../species-list-table/SpeciesListTable';
import styles from './IucnStatusChips.module.scss';

type IucnStatusChipProps = Pick<SpeciesListTableData, 'iucnStatusType' | 'isInvasive' | 'iucnStatusLabel'> & {
    shortedLabel?: boolean;
};

const IucnStatusChips = (props: IucnStatusChipProps) => {
    const { iucnStatusType, isInvasive, iucnStatusLabel, shortedLabel = false } = props;

    return (
        <>
            {iucnStatusType && <span className={classNames([styles.chip, styles[iucnStatusType]])}>{iucnStatusLabel}</span>}
            &nbsp;
            {isInvasive && <span className={classNames([styles.chip, styles.invasive])}>{shortedLabel ? 'INV' : 'Invasive'}</span>}
        </>
    );
};

export default IucnStatusChips;
