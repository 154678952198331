import { TaxonomySummary } from 'src/shared/types/graph/project';
import * as d3 from 'd3';
import { TaxonomicChartSortOptions } from 'src/app/insights/state/taxonomic-composition/taxonomicCompositionSlice';
import uniq from 'lodash/uniq';

enum AssayTypes {
    INVERTEBRATES = 'invertebrates',
    VERTEBRATES = 'vertebrates',
    BROADEUKARYOTES = 'broadeukaryotes',
    METAZOANS = 'metazoans',
    BACTERIA = 'bacteria',
    FUNGI = 'fungi',
    FISH = 'fish',
    MAMMALS = 'mammals',
}

export type TaxonomicChartNodeData = {
    name: string;
    value: number;
    leaf?: boolean;
    children?: TaxonomicChartNodeData[];
    totalChildren: number;
    totalspecies: number;
    id: string;
    isOpaqued: boolean;
    isHidden: boolean;
};

export enum TaxonomicChartColumns {
    tax_kingdom = 'tax_kingdom',
    tax_phylum = 'tax_phylum',
    tax_class = 'tax_class',
    tax_order = 'tax_order',
    tax_family = 'tax_family',
    tax_genus = 'tax_genus',
    tax_species = 'tax_species',
}

type NonNullTaxonomicSummary = {
    value: number;
    percentageValue: number;
    [TaxonomicChartColumns.tax_kingdom]: string;
    [TaxonomicChartColumns.tax_phylum]: string;
    [TaxonomicChartColumns.tax_class]: string;
    [TaxonomicChartColumns.tax_family]: string;
    [TaxonomicChartColumns.tax_order]: string;
    [TaxonomicChartColumns.tax_genus]: string;
    [TaxonomicChartColumns.tax_species]: string;
};

type GetNonNullTaxonomySummaryProps = {
    taxonomySummary: TaxonomySummary;
    kingdoms: string[];
    phylums: string[];
    classes: string[];
};

export const getFilteredTaxonomySummary = (props: GetNonNullTaxonomySummaryProps): NonNullTaxonomicSummary[] => {
    const { taxonomySummary, phylums, kingdoms, classes } = props;

    return taxonomySummary
        .filter(entry => {
            const { tax_kingdom, tax_phylum, tax_class } = entry.taxonomy;
            if (tax_kingdom && kingdoms.length && tax_phylum && phylums.length && tax_class && classes.length) {
                if (!kingdoms.includes(tax_kingdom) || !phylums.includes(tax_phylum) || !classes.includes(tax_class)) {
                    return false;
                }
            }

            return true;
        })
        .map(entry => {
            return {
                value: entry.sampleCount,
                percentageValue: entry.percentTotalSamples,
                tax_kingdom: entry.taxonomy.tax_kingdom || 'Unassigned',
                tax_phylum: entry.taxonomy.tax_phylum || 'Unassigned',
                tax_class: entry.taxonomy.tax_class || 'Unassigned',
                tax_family: entry.taxonomy.tax_family || 'Unassigned',
                tax_order: entry.taxonomy.tax_order || 'Unassigned',
                tax_genus: entry.taxonomy.tax_genus || 'Unassigned',
                tax_species: entry.taxonomy.tax_species || 'Unassigned',
            };
        }) as NonNullTaxonomicSummary[];
};

type ConvertDataProps = {
    taxonomySummary: TaxonomySummary;
    sortColumnsBy: string;
    metric: string;
    kingdoms: string[];
    phylums: string[];
    classes: string[];
    selectedColumns: TaxonomicChartColumns[];
    hierarchy: string;
    highlightedBranchNodeId: string | null;
    visibleBranchNodeId: string | null;
};
type MinMaxValues = {
    min: number;
    max: number;
};

export const columnOrder = [
    TaxonomicChartColumns.tax_kingdom,
    TaxonomicChartColumns.tax_phylum,
    TaxonomicChartColumns.tax_class,
    TaxonomicChartColumns.tax_order,
    TaxonomicChartColumns.tax_family,
    TaxonomicChartColumns.tax_genus,
    TaxonomicChartColumns.tax_species,
];

const defaultVisibleColumns = [
    TaxonomicChartColumns.tax_class,
    TaxonomicChartColumns.tax_order,
    TaxonomicChartColumns.tax_family,
    TaxonomicChartColumns.tax_genus,
];

const assayTypeVisibleColumnsOverride: Partial<Record<AssayTypes, TaxonomicChartColumns[]>> = {
    [AssayTypes.BROADEUKARYOTES]: [
        TaxonomicChartColumns.tax_kingdom,
        TaxonomicChartColumns.tax_phylum,
        TaxonomicChartColumns.tax_class,
        TaxonomicChartColumns.tax_order,
        TaxonomicChartColumns.tax_family,
    ],
    [AssayTypes.METAZOANS]: [
        TaxonomicChartColumns.tax_kingdom,
        TaxonomicChartColumns.tax_phylum,
        TaxonomicChartColumns.tax_class,
        TaxonomicChartColumns.tax_order,
        TaxonomicChartColumns.tax_family,
    ],
    [AssayTypes.BACTERIA]: [
        TaxonomicChartColumns.tax_phylum,
        TaxonomicChartColumns.tax_class,
        TaxonomicChartColumns.tax_order,
        TaxonomicChartColumns.tax_family,
        TaxonomicChartColumns.tax_genus,
    ],
    [AssayTypes.FUNGI]: [
        TaxonomicChartColumns.tax_phylum,
        TaxonomicChartColumns.tax_class,
        TaxonomicChartColumns.tax_order,
        TaxonomicChartColumns.tax_family,
    ],
};

export const getDefaultColumnsForAssayType = (assay: string) => {
    return assayTypeVisibleColumnsOverride[assay as AssayTypes] || defaultVisibleColumns;
};

const defaultNode = {
    name: '',
    value: 0,
    totalChildren: 0,
} as TaxonomicChartNodeData;

export const getTaxonomicChartData = (props: ConvertDataProps): TaxonomicChartNodeData[] => {
    const {
        taxonomySummary,
        sortColumnsBy,
        kingdoms,
        phylums,
        classes,
        selectedColumns,
        hierarchy,
        highlightedBranchNodeId,
        visibleBranchNodeId,
    } = props;
    const filteredTaxonomicSummary = getFilteredTaxonomySummary({
        taxonomySummary,
        kingdoms,
        phylums,
        classes,
    });

    const tree: TaxonomicChartNodeData[] = [];

    if (!selectedColumns.length) {
        return tree;
    }

    const columns = hierarchy === 'high_to_low' ? selectedColumns : [...selectedColumns].reverse();

    // Insert the root nodes - Level 1
    uniq(filteredTaxonomicSummary.map(taxonomy => taxonomy[columns[0]])).forEach(name => {
        let value = 0;
        let totalspecies = 0;

        const totalChildren = uniq(
            filteredTaxonomicSummary
                .filter(item => item[columns[0]] === name)
                .map(entry => {
                    value += entry.value;
                    totalspecies += 1;
                    return [
                        entry[columns[0]],
                        entry[columns[1]],
                        entry[columns[2]],
                        entry[columns[3]],
                        entry[columns[4]],
                        entry[columns[5]],
                        entry[columns[6]],
                    ].join();
                })
        ).length;

        const isOpaqued = !highlightedBranchNodeId ? false : !highlightedBranchNodeId.includes(name);
        const isHidden = !visibleBranchNodeId ? false : !visibleBranchNodeId.includes(name);

        tree.push({
            ...defaultNode,
            children: [],
            name,
            value,
            totalChildren,
            leaf: !columns[1],
            id: name,
            totalspecies,
            isOpaqued,
            isHidden,
        } as TaxonomicChartNodeData);
    });

    let parents = tree;

    for (let index = 1; index < columns.length; index++) {
        parents.forEach(parent => {
            const currentParentTaxonomy = filteredTaxonomicSummary.filter(item => {
                const id = columns
                    .slice(0, index)
                    .map(column => item[column])
                    .join('.');

                return id === parent.id;
            });

            const uniqueChildrenNames = uniq(currentParentTaxonomy.map(taxonomy => taxonomy[columns[index]]));

            uniqueChildrenNames.forEach(name => {
                const isLeaf = !columns[index + 1];

                const currentParentChildren = uniq(currentParentTaxonomy.filter(item => item[columns[index]] === name));

                let value = 0;
                let totalspecies = 0;
                const totalChildren = uniq(
                    currentParentChildren.map(entry => {
                        value += entry.value;
                        totalspecies += 1;
                        return [
                            entry[columns[0]],
                            entry[columns[1]],
                            entry[columns[2]],
                            entry[columns[3]],
                            entry[columns[4]],
                            entry[columns[5]],
                            entry[columns[6]],
                        ].join();
                    })
                ).length;

                const currentNodeId = parent.id + '.' + name;

                const isOpaqued = !highlightedBranchNodeId
                    ? false
                    : !(highlightedBranchNodeId.includes(currentNodeId) || currentNodeId.includes(highlightedBranchNodeId));

                const isHidden = !visibleBranchNodeId
                    ? false
                    : !(visibleBranchNodeId.includes(currentNodeId) || currentNodeId.includes(visibleBranchNodeId));
                parent.children?.push({
                    ...defaultNode,
                    children: columns[index + 1] ? [] : undefined,
                    name: name,
                    value,
                    totalChildren,
                    leaf: isLeaf,
                    id: currentNodeId,
                    totalspecies,
                    isOpaqued,
                    isHidden,
                } as TaxonomicChartNodeData);
            });
        });
        parents = parents.filter(entry => entry.children).flatMap(entry => entry.children as TaxonomicChartNodeData[]);
    }

    return getSortedTaxonomicSummary(tree, sortColumnsBy);
};

export const getSortedTaxonomicSummary = (tree: TaxonomicChartNodeData[], sortColumnsBy: string): TaxonomicChartNodeData[] => {
    const sorter = (a: TaxonomicChartNodeData, b: TaxonomicChartNodeData) => {
        if (sortColumnsBy === TaxonomicChartSortOptions.metric_low_to_high) {
            return a.value - b.value;
        }

        if (sortColumnsBy === TaxonomicChartSortOptions.alphabetically_a_to_z) {
            if (a.name < b.name) {
                return -1;
            }
            if (a.name > b.name) {
                return 1;
            }
            return 0;
        }

        if (sortColumnsBy === TaxonomicChartSortOptions.alphabetically_z_to_a) {
            if (b.name < a.name) {
                return -1;
            }
            if (b.name > a.name) {
                return 1;
            }
            return 0;
        }

        return b.value - a.value;
    };

    const recursiveSort = (node: TaxonomicChartNodeData): TaxonomicChartNodeData => {
        if (node.children) {
            node.children.sort(sorter);
            node.children.forEach(childNode => {
                return recursiveSort(childNode);
            });
        }
        return node;
    };
    tree.sort(sorter).forEach(recursiveSort);
    return tree;
};

export const getBlockColors = (value: number, minMaxValues: MinMaxValues) => {
    const colorsArray = ['#FFFFE0', '#BBE4D1', '#76C7BE', '#3EA8A6', '#208288', '#076769', '#00494B', '#002C2D'];
    const textColorsArray = ['#000000', '#000000', '#000000', '#000000', '#ffffff', '#ffffff', '#ffffff', '#ffffff'];
    if (minMaxValues.min > 0) {
        colorsArray.shift();
        textColorsArray.shift();
    }

    const backgroundColorScale = d3
        .scaleQuantile()
        .domain([0, minMaxValues.max])
        .range(colorsArray as any);

    const textColorScale = d3
        .scaleQuantile()
        .domain([0, minMaxValues.max])
        .range(textColorsArray as any);

    return {
        color: String(textColorScale(value)),
        backgroundColor: String(backgroundColorScale(value)),
    };
};
