import { gql } from 'graphql-request';
import { ChartIds } from 'src/shared/types/graph/chartData';
export const statisticalComparisonQuery = gql`
    query statisticalComparisonData($projectId: String!, $fromDate: String, $toDate: String, $habitatAssay: String!) {
        statisticalComparison: chart(
            projectId: $projectId, 
            chartId: "${ChartIds.STATISTICAL_COMPARISON}", 
            habitatAssay: $habitatAssay,
            fromDate: $fromDate, 
            toDate: $toDate
        ) {
            chartId
            chartType
            chartTitle
            description
            data
        }
    }
`;

export type StatisticalComparisonBoxData = {
    comparisons: {
        comparedTo: string;
        p_bayes: number;
        sig_diff: boolean;
    }[];
    median: number;
    'q0.025': number;
    'q0.25': number;
    'q0.75': number;
    'q0.975': number;
    valuesY: {
        sampleId: string;
        value: number;
    }[];
    variable: {
        key: string;
        value: string;
    };
};

type StatisticalComparisonData = {
    chartId: string;
    chartType: string;
    chartTitle: string;
    description: string;
    data: {
        metric: string;
        years: {
            boxes: StatisticalComparisonBoxData[];
            samplingYear: string;
        }[];
    }[];
};

export type StatisticalComparisonQueryResult = {
    statisticalComparison: StatisticalComparisonData;
};
