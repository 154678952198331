import React, { useEffect, useState } from 'react';
import moment from 'moment';
import uniq from 'lodash/uniq';
import useProject from 'src/shared/hooks/useProject';
import { useIucnMetricsQuery } from 'src/app/dashboard/state/metrics/metricsGraphSlice';
import { LinearLoader } from 'src/shared/components/loader/Loader';
import { getCurrentDashboardUIState } from 'src/app/dashboard/state/dashboardGeneralSelectors';
import { useAppDispatch, useAppSelector } from 'src/store';
import IucnTable from './component/IucnTable';
import { filterByRedListStatus, filterByGRIISList } from './IucnMetrics.utils';
import { getQuarterDateRange, getDateQuarterSelectOption } from 'src/shared/utils/dateHelpers';
import { updateIucnMetricUiState } from 'src/app/dashboard/state/dashboardGeneralSlice';
import styles from './IucnMetrics.module.scss';
import Select, { SelectOption } from 'src/shared/components/select/Select';
import useDashboardAnalytics from 'src/app/dashboard/hooks/useDashboardAnalytics';
import useIsViewed from 'src/shared/hooks/useIsViewed';
import useRefWithRerender from 'src/shared/hooks/useRefWithRerender';
import MetricCard from 'src/shared/components/metric-card/MetricCard';
import QuestionButtonHelp from 'src/shared/components/question-button-help/QuestionButtonHelp';
import IUCNLogo from 'src/assets/images/dashboard/iucn.png';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import InputLabel from '@mui/material/InputLabel';

type IucnMetricsProps = {
    isSingleTimepoint?: boolean;
    hideDateFilter?: boolean;
};
const IucnMetrics = (props: IucnMetricsProps) => {
    const { isSingleTimepoint, hideDateFilter } = props;
    const { currentProjectId, currentProjectDetails, currentProjectSelectedHabitatAssayType: habitatAssayType } = useProject();
    const [timepointsOptions, setTimesPointOptions] = useState<SelectOption[]>([]);
    const uiState = useAppSelector(getCurrentDashboardUIState);
    const dispatch = useAppDispatch();
    const [ref, callbackRef] = useRefWithRerender<HTMLDivElement>(null);
    const isViewed = useIsViewed(ref);
    const { trackIUCNTableViewed } = useDashboardAnalytics();

    const {
        iucnMetric: { selectedTimePoint },
    } = uiState;

    const { quarterStart, quarterEnd } = getQuarterDateRange(selectedTimePoint) || {};

    const { currentData, isFetching } = useIucnMetricsQuery(
        {
            projectId: currentProjectId || '',
            samples: {
                fromDate: quarterStart,
                toDate: quarterEnd,
                habitatAssay: habitatAssayType?.key || '',
            },
        },
        {
            skip: !currentProjectId || !habitatAssayType,
        }
    );

    useEffect(() => {
        if (isViewed) {
            trackIUCNTableViewed();
        }
    }, [isViewed]);

    useEffect(() => {
        if (!currentProjectDetails || !currentProjectDetails.samples.length) {
            return;
        }

        const samples = currentProjectDetails.samples;
        const uniqueSampleDates = uniq(samples.map(entry => moment(entry.sampleCollectedDatetime).startOf('Q').valueOf())).sort();

        const newTimepointOptions = uniqueSampleDates.map(entry => getDateQuarterSelectOption(entry)).reverse();
        setTimesPointOptions(newTimepointOptions);
        if (!selectedTimePoint && newTimepointOptions.length > 0) {
            onTimePointChange(newTimepointOptions[0].value);
        }
    }, [currentProjectDetails]);

    const onTimePointChange = (timePointValue: string) => {
        dispatch(updateIucnMetricUiState({ selectedTimePoint: timePointValue }));
    };

    if (!currentData || isFetching) {
        return (
            <>
                <LinearLoader />
            </>
        );
    }

    const { iucnMetrics: iucnMetricsData } = currentData;
    const { taxonomySummary } = iucnMetricsData;

    const iucnRedLists = filterByRedListStatus(taxonomySummary);
    const invasiveLists = filterByGRIISList(taxonomySummary);

    const header = (
        <div>
            <div className={styles.filter} ref={callbackRef}>
                <div className={styles.title}>Results in this table are only displayed for one quarter</div>
                <div>|</div>
                <div className={styles.filter}>
                    <InputLabel className={styles.filterLabel}>Display detections in</InputLabel>
                    <div className={styles.filterSelect}>
                        <Select
                            width={175}
                            options={timepointsOptions}
                            onChange={value => onTimePointChange(value)}
                            selectedValue={selectedTimePoint || timepointsOptions[0]?.value}
                        />
                    </div>
                </div>
            </div>
        </div>
    );

    const tables = (
        <div>
            <IucnTable data={[...iucnRedLists]} title='IUCN Red List species' type='redLists' helpArticleSlug='iucn-status' />
            <IucnTable data={[...invasiveLists]} title='Invasive species' type='invasiveLists' helpArticleSlug='invasive-species' />
        </div>
    );

    if (isSingleTimepoint) {
        return (
            <>
                <MetricCard
                    header={'IUCN Red List'}
                    content='Red List species detected'
                    helpText={<QuestionButtonHelp type='api' placement='right' slug='iucn-status' />}
                    total={iucnRedLists.length}
                    logo={<img src={IUCNLogo} alt='Logo' style={{ marginRight: '5px' }} />}
                />
                <MetricCard
                    header={'Invasive Species'}
                    content='invasive species detected'
                    helpText={<QuestionButtonHelp type='api' placement='right' slug='invasive-species' />}
                    total={invasiveLists.length}
                    logo={<InfoOutlinedIcon style={{ color: '#E42322', marginRight: '5px', rotate: '180deg' }} />}
                />
            </>
        );
    }

    return (
        <div className={styles.iucnMetrics}>
            {!hideDateFilter && header}
            {tables}
        </div>
    );
};

export default IucnMetrics;
