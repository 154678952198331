import { LineStyleType } from '../charts';

export type TimeSeriesData = {
    datetime: string;
    group: IndividualTypeValue[];
    site?: string;
    chartType?: string;
};

export type TimeSeriesChartData = {
    type: string;
    data: { date: number; value: number | number[] }[];
    color: string;
    label: string;
    styleType?: LineStyleType;
};

export type IndividualTypeValue = {
    type: string;
    value: number | number[];
    boundary?: number;
    annualRange?: { min: number; max: number };
};

export enum ChartIds {
    FISH_METRICS = 'fishmetrics',
    IUCN_THREATENED_SPECIES = 'iucnthreatenedspecies',
    EVOLUTIONARY_DIVERSITY = 'evolutionarydiversity',
    SPECIES_RICHNESS = 'speciesrichness',
    STATISTICAL_COMPARISON = 'statcomparison',
    COMMUNITY_COMPARISON = 'communitycomparison',
    BACTERIAL_FUNCTIONAL_DIVERSITY = 'bfd',
    FUNGAL_FUNCTIONAL_DIVERSITY = 'ffd',
}
