import React, { lazy, Suspense } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import { ReactComponent as NMlogo } from 'src/assets/svg/logo_md.svg';
import NMbackground from 'src/assets/images/img-login-background.webp';

import Signin from 'src/app/login/views/Signin';
import ForgotPassword from 'src/app/login/views/ForgotPassword';
import VerifyCustomChallenge from 'src/app/login/views/VerifyCustomChallenge';
import SetNewPassword from 'src/app/login/views/SetNewPassword';

import styles from './Login.module.scss';
import TermsOfUseDeclined from './views/terms-of-use/TermsOfUseDeclined';

// Lazy load the terms of use component
const TermsOfUseComponent = lazy(() => import('./views/terms-of-use/TermsOfUse'));

const Login = () => {
    const { pathname } = useLocation();

    const isTermsOfUsePage = pathname.includes('terms-of-use');

    return (
        <div className={styles.loginContainer} style={{ backgroundImage: `url(${NMbackground})` }}>
            <div className={styles.centerBoxContainer}>
                <div className={!isTermsOfUsePage ? styles.centerBox : undefined}>
                    {!isTermsOfUsePage && (
                        <div className={styles.logoContainer}>
                            <NMlogo />
                        </div>
                    )}
                    <Routes>
                        <Route path={'/forgot-password'} element={<ForgotPassword />} />
                        <Route path={'/verify-custom-challenge'} element={<VerifyCustomChallenge />} />
                        <Route path={'/set-new-password'} element={<SetNewPassword />} />
                        <Route
                            path={'/terms-of-use'}
                            element={
                                <Suspense fallback='Loading...'>
                                    <TermsOfUseComponent />
                                </Suspense>
                            }
                        />
                        <Route path={'/terms-of-use-declined'} element={<TermsOfUseDeclined />} />
                        <Route path={'/'} element={<Signin />} />
                    </Routes>
                </div>
            </div>
        </div>
    );
};
export default Login;
