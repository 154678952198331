import classNames from 'classnames';
import React from 'react';

import useProjectDefinition from 'src/app/projects/views/new-project-definition/hooks/useProjectDefinition';
import styles from 'src/app/projects/views/new-project-definition/NewProjectDefinition.module.scss';
import ErrorMessage from 'src/shared/components/error-msg/ErrorMessage';
import InfoMessage from 'src/shared/components/info-message/InfoMessage';
import Input from 'src/shared/components/input/Input';
import { SubscriptionType } from 'src/shared/types/graph/project';
// TODO: ProjectDefinitionMap import { SubscriptionType } from 'src/shared/types/graph/project';
// import SamplesUpload from './components/SamplesUpload';
// import SamplesReview from './components/SamplesReview';

export const Samples = () => {
    const { formState, onFormChange } = useProjectDefinition();

    const {
        habitatAssayTypes,
        estimatedSampleCountForTestTypes,
        initialSampleEstimate,
        subscriptionType,
        // TODO: ProjectDefinitionMap
        // uploadedFileContent,
        // initialSampleEntries,
    } = formState;

    // TODO: ProjectDefinitionMapTemporarily disabling this until we need it
    // if (subscriptionType === SubscriptionType.INSIGHTS) {
    //     const formViewClassNames = classNames(styles.formView, styles.formViewFullWidth);
    //     return (
    //         <div className={formViewClassNames}>
    //             <div className={styles.formViewTitle}>Samples</div>
    //             {!uploadedFileContent && !initialSampleEntries.length && <SamplesUpload />}
    //             {(uploadedFileContent || initialSampleEntries.length > 0) && <SamplesReview />}
    //         </div>
    //     );
    // }

    if (subscriptionType === SubscriptionType.INSIGHTS) {
        return (
            <div className={styles.formView}>
                <div className={styles.formViewTitle}>Samples</div>
                <InfoMessage
                    message={
                        <>
                            <div>COMING SOON...</div>
                            <br />
                            Ability to upload a data file to specify details of each sample. <br />
                            This will display as a map on the summary page
                        </>
                    }
                />
            </div>
        );
    }

    const onSamplesCountChange = (index: number, samplesCount: number) => {
        const estimatedSampleCountCopy = [...estimatedSampleCountForTestTypes];
        estimatedSampleCountCopy[index] = {
            type: habitatAssayTypes[index],
            count: samplesCount,
        };
        onFormChange({ estimatedSampleCountForTestTypes: estimatedSampleCountCopy });
    };

    return (
        <div className={styles.formView}>
            <div className={styles.formViewTitle}>Samples</div>

            <div className={styles.formSection}>
                <div className={styles.formInputSection} style={{ width: '50%' }}>
                    <div className={styles.formInputSectionTitle}>Total number of project samples</div>
                    <Input
                        value={initialSampleEstimate || ''}
                        onChange={event => onFormChange({ initialSampleEstimate: Number(event.target.value) })}
                        type='number'
                    />
                </div>
            </div>
            <div className={styles.formSection}>
                <div className={styles.formSectionTitle}>Number of samples per test </div>
                {estimatedSampleCountForTestTypes.map((entry, index) => {
                    const hasError = (entry.count || 0) > initialSampleEstimate;
                    const sectionClassNames = classNames(styles.formInputSection, {
                        [styles.formInputSectionError]: hasError,
                    });
                    return (
                        <div className={styles.formSection} key={index}>
                            <div className={sectionClassNames}>
                                <div className={styles.formInputSectionTitle}>{`${entry.type.habitatName} - ${entry.type.assayName}`}</div>
                                <Input
                                    value={entry.count || ''}
                                    onChange={event => onSamplesCountChange(index, Number(event.target.value))}
                                    type='number'
                                    hasError={hasError}
                                />
                            </div>
                        </div>
                    );
                })}
            </div>

            {estimatedSampleCountForTestTypes.some(entry => (entry?.count || 0) > initialSampleEstimate) && (
                <div className={styles.formSection}>
                    <ErrorMessage message='The number of samples per test(s) cannot be higher than the total number of project samples' />
                </div>
            )}
        </div>
    );
};

export default Samples;
